import React from 'react';
import capitalize from 'lodash/capitalize';
import axios from 'axios';
import Swal from 'sweetalert2';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import ConnectorChoice from './Components/ConnectorChoice';
import DataMapping from './Components/DataMappingComponents/DataMapping';
import DataModel from './Components/DataModel';
import Navbar from './Components/Navbar';
import Overview from './Components/Overview';
import ReviewConfiguration from './Components/ReviewConfiguration';
import SubHeader from './Components/SubHeader';
import AdminPushConfiguration from './Components/AdminPushConfiguration';
import ReviewYamlJson from './Components/ReviewYamlJson';
import {
  hasConnectorActive,
  getJson,
  saveJson,
  updateExportContacts,
  fetchModelList,
  removeNullish,
} from './utils';
import {
  BasePushConfig,
  Connectors,
  DatePicked,
  Models,
  PickedObject,
  RoundLgYaml,
  RoundLtbYaml,
  JsonObject,
  PushConfigurationItem,
  Integration,
  IntegrationsConfig,
} from './types';

import ModelDataModel from '../../models/model/ModelDataModel';
import { slackNotification } from '../../utils';
import {
  ADMIN_VIEW_STEP,
  CONNECTOR_STEP,
  DATA_MAPPING_STEP,
  EDIT_CONFIGURATION_STEPS,
  EDIT_VIEW_STEP,
  FORCE_PUSH_STEP,
  OVERVIEW_STEP,
  REVIEW_CONFIGURATION_STEP,
  REVIEW_YAML_STEP,
} from './constants';
import ForcePush from './Components/ForcePush';
import { getAllActiveIntegrations } from '../../mapping/utils';
import ActiveIntegrationDataModel from '../../mapping/event_mapping/models/ActiveIntegrationDataModel';
import { IS_HYBRID } from '../../utils/constants';
import { ModelTypes } from '../../types';
import { Loader } from '../../components/Loader';

type PushConfigurationProps = {
  isSuperKudu: boolean;
  tenant: number;
  userIsReadonly: boolean;
  userCanEdit: boolean;
  userEmail: string;
  pageName: string;
  toggleView: Function;
};

type PushConfigurationState = {
  isLoading: boolean;
  dataModelFormHasBeenSubmitted: boolean;
  pushConfiguration: PushConfigurationItem[];
  currentStep: string;
  pickedModel: Models;
  integrationList: ActiveIntegrationDataModel[];
  models: ModelDataModel[];
  connectors: Connectors;
  basePushConfig: BasePushConfig;
  yamlPickedModel: {
    round_behavioral_scores: boolean;
    round_lead_grade_scores: boolean;
  };
  yamlPickedConnectors: {
    has_salesforce: boolean;
    has_hubspot: boolean;
    has_segment: boolean;
  };
  datePicked: DatePicked;
  roundLgYaml: RoundLgYaml;
  roundLtbYaml: RoundLtbYaml;
  pickedObject: PickedObject;
  userEmail: string;
  activePage: string;
  ltbModels: number[];
  lgModels: number[];
  hybridEnabled: boolean;
  hasHybridModels: boolean;
  aggregationsFields: string[];
  computationsFields: string[];
  isCustomPushConfig: boolean;
};

const JSON_OBJECT: JsonObject = {
  batch: false,
  realtime: false,
  name: '',
  type: '',
  integrations: {
    all: false,
  },
};

const FIELD_NAMES: { [key: string]: string[] } = {
  customerFit: [
    'mk_customer_fit_segment',
    'mk_customer_fit_score',
    'mk_customer_fit_signals',
  ],
  likelihoodToBuy: [
    'mk_likelihood_to_buy_segment',
    'mk_likelihood_to_buy_score',
    'mk_likelihood_to_buy_signals',
  ],
  leadGrade: ['mk_lead_grade_segment', 'mk_lead_grade_score'],
  aggregations: [],
  computations: [],
};

export default class PushConfiguration extends React.Component<
  PushConfigurationProps,
  PushConfigurationState
> {
  constructor(props: PushConfigurationProps) {
    super(props);
    const currentView = this.props.pageName;
    this.state = {
      hybridEnabled: false,
      hasHybridModels: false,
      ltbModels: [],
      lgModels: [],
      aggregationsFields: [],
      computationsFields: [],
      activePage: currentView,
      currentStep: currentView,
      userEmail: this.props.userEmail,
      isLoading: true,
      dataModelFormHasBeenSubmitted: false,
      pushConfiguration: [],
      integrationList: [],
      models: [],
      yamlPickedConnectors: {
        has_salesforce: false,
        has_hubspot: false,
        has_segment: false,
      },
      basePushConfig: {
        has_customer_fit: false,
        has_likelihood_to_buy: false,
        has_lead_grade: false,
        has_aggregations: false,
        has_computations: false,
      },
      roundLgYaml: {
        round_lead_grade_scores: false,
        modulo_to_round_A_grades: 1,
        modulo_to_round_B_grades: 1,
        modulo_to_round_C_grades: 1,
        modulo_to_round_D_grades: 1,
        modulo_to_round_E_grades: 1,
      },
      roundLtbYaml: {
        round_behavioral_scores: false,
        modulo_to_round_already_paying_behavioral_scores: 1,
        modulo_to_round_very_high_behavioral_scores: 1,
        modulo_to_round_high_behavioral_scores: 1,
        modulo_to_round_medium_behavioral_scores: 1,
        modulo_to_round_low_behavioral_scores: 1,
      },
      datePicked: {
        salesforce: {
          limit_scoring_timeframe: true,
          score_contacts_created_since: '',
        },
        hubspot: {
          limit_scoring_timeframe: true,
          score_contacts_created_since: '',
        },
        segment: {
          limit_scoring_timeframe: true,
          score_contacts_active_last_X_days_segment: 30,
        },
      },
      yamlPickedModel: {
        round_behavioral_scores: false,
        round_lead_grade_scores: false,
      },
      connectors: {
        salesforce: false,
        hubspot: false,
        intercom: false,
        segment: false,
      },
      pickedModel: {
        customerFit: false,
        likelihoodToBuy: false,
        leadGrade: false,
        aggregations: false,
        computations: false,
      },
      pickedObject: {
        salesforce: { lead: false, contact: false },
        hubspot: { lead: false, contact: false },
        intercom: { lead: false, contact: false },
        segment: { lead: false, contact: false, identify: false },
      },
      isCustomPushConfig: false,
    };
    this.changePageState = this.changePageState.bind(this);
    this.renderAdminView = this.renderAdminView.bind(this);
    this.renderDataModel = this.renderDataModel.bind(this);
    this.renderConnectorChoice = this.renderConnectorChoice.bind(this);
    this.handleModelSelection = this.handleModelSelection.bind(this);
    this.handleScoresChangeLtb = this.handleScoresChangeLtb.bind(this);
    this.handleScoresChangeLg = this.handleScoresChangeLg.bind(this);
    this.handleConnectorSelection = this.handleConnectorSelection.bind(this);
    this.handleDataMappingChange = this.handleDataMappingChange.bind(this);
    this.handleTextAreaAdmin = this.handleTextAreaAdmin.bind(this);
    this.handleChangeObject = this.handleChangeObject.bind(this);
    this.handleDatePick = this.handleDatePick.bind(this);
    this.isDateValid = this.isDateValid.bind(this);
    this.handleTimeLimitCheck = this.handleTimeLimitCheck.bind(this);
    this.handleYamlPickedModel = this.handleYamlPickedModel.bind(this);
    this.handleLikelihoodToBuyChange = this.handleLikelihoodToBuyChange.bind(
      this
    );

    this.handleLeadGradeChange = this.handleLeadGradeChange.bind(this);
    this.handleComputationsChange = this.handleComputationsChange.bind(this);
    this.handleAggregationsChange = this.handleAggregationsChange.bind(this);
    this.handleSegmentTimeFrameChange = this.handleSegmentTimeFrameChange.bind(
      this
    );
    this.createArrayOfModels = this.createArrayOfModels.bind(this);
    this.hasModel = this.hasModel.bind(this);
    this.reloadComponent = this.reloadComponent.bind(this);
    this.onConfirmStepModel = this.onConfirmStepModel.bind(this);
    this.onConfirmConnectors = this.onConfirmConnectors.bind(this);
    this.generateModelBadges = this.generateModelBadges.bind(this);
    this.handleNavbarPageToggle = this.handleNavbarPageToggle.bind(this);
    this.onConfirmDataMapping = this.onConfirmDataMapping.bind(this);
  }

  async componentDidMount() {
    const { tenant, toggleView, userEmail } = this.props;
    window?.analytics?.page('Push configuration', {
      email: userEmail,
      tenant,
    });

    toggleView(this.state.activePage);

    const promises = [
      this.checkIfConfigIsCustom(),
      fetchModelList(tenant),
      getAllActiveIntegrations(tenant),
      getJson(tenant),
    ];

    const [
      isCustomPushConfig,
      models,
      integrations,
      fetchedJsonPushConfiguration,
    ] = await Promise.all(promises);

    this.setState({
      isCustomPushConfig,
      models,
      integrationList: integrations,
      hasHybridModels: models.some(({ type }: { type: ModelTypes }) =>
        IS_HYBRID(type)
      ),
    });

    const contactUpdateObject = this.getPushContactConfiguration(
      fetchedJsonPushConfiguration
    );
    const alreadyConfiguredAggregationsFields: string[] = [];
    contactUpdateObject?.attributes?.forEach((entry: any) => {
      if (entry?.tag === 'aggregation') {
        alreadyConfiguredAggregationsFields.push(entry.name);
      }
    });

    const alreadyConfiguredComputationsFields: string[] = [];
    contactUpdateObject?.attributes?.forEach((entry: any) => {
      if (entry?.tag === 'computation') {
        alreadyConfiguredComputationsFields.push(entry.name);
      }
    });
    const ltbModels = new Set<number>();
    const lgModels = new Set<number>();
    let hybridEnabled = false;

    contactUpdateObject?.attributes?.forEach(({ name }: { name: string }) => {
      const hybridMatch = name?.match(
        /mk_(likelihood_to_buy|lead_grade)_.*_(\d+)$/
      );
      if (hybridMatch) {
        hybridEnabled = true;
        if (hybridMatch[1] === 'likelihood_to_buy')
          ltbModels.add(Number(hybridMatch[2]));
        if (hybridMatch[1] === 'lead_grade')
          lgModels.add(Number(hybridMatch[2]));
      }
    });

    const liveModel: ModelDataModel = models.find(
      (model: ModelDataModel) => model.type === 'pql'
    );
    if (liveModel) {
      ltbModels.add(liveModel.modelId);
      lgModels.add(liveModel.modelId);
    }

    const configuration = contactUpdateObject?.configuration;
    const pickedModel = configuration?.models;
    this.setState({
      ltbModels: Array.from(ltbModels),
      lgModels: Array.from(lgModels),
      hybridEnabled,
    });

    if (fetchedJsonPushConfiguration.length !== 0 && configuration) {
      this.setState((prevState) => ({
        aggregationsFields: alreadyConfiguredAggregationsFields,
        computationsFields: alreadyConfiguredComputationsFields,
        pushConfiguration: fetchedJsonPushConfiguration,
        isLoading: false,
        connectors: configuration.connectors,
        pickedModel,
        datePicked: configuration.timeframe,
        pickedObject: configuration.objects,
        yamlPickedModel: {
          round_lead_grade_scores:
            configuration?.roundLg?.round_lead_grade_scores ?? false,
          round_behavioral_scores:
            configuration?.roundLtb?.round_behavioral_scores ?? false,
        },
        roundLtbYaml: configuration?.roundLtb ?? prevState.roundLtbYaml,
        roundLgYaml: configuration?.roundLg ?? prevState.roundLgYaml,
      }));
    } else {
      this.setState({
        pushConfiguration: fetchedJsonPushConfiguration,
        isLoading: false,
      });
    }
  }

  async reloadComponent() {
    window.location.reload();
  }

  hasModel(modelType: string) {
    return this.state.models.some(({ type }) => type === modelType);
  }

  createArrayOfModels() {
    const arrayOfModels: string[] = [];
    const hasCustomerFit = this.hasModel('customer_fit');
    const hasLtb = this.hasModel('pql');
    if (hasCustomerFit) {
      arrayOfModels.push('customerFit');
    }
    if (hasLtb) {
      arrayOfModels.push('likelihoodToBuy', 'leadGrade');
    }
    arrayOfModels.push('aggregations', 'computations');

    return arrayOfModels;
  }

  handleNavbarPageToggle(pageName: string) {
    this.setState({
      currentStep: pageName,
    });
  }

  handleSegmentTimeFrameChange(e: any) {
    this.setState({
      datePicked: {
        ...this.state.datePicked,
        segment: {
          ...this.state.datePicked.segment,
          score_contacts_active_last_X_days_segment: e.target.value,
        },
      },
    });
  }

  changePageState(pageName: string) {
    this.setState({ currentStep: pageName });
  }

  generateModelBadges(modelName: string, tag?: string, model?: ModelDataModel) {
    const badges: Record<string, { name: string; class: string }> = {
      fit: { name: 'Customer Fit', class: 'cfit-badge' },
      likelihood: { name: 'Likelihood to Buy', class: 'mqa-badge' },
      grade: { name: 'Lead Grade', class: 'pql-badge' },
      computation: { name: 'Computations', class: 'badge-danger' },
      aggregation: { name: 'Aggregations', class: 'badge-warning' },
    };

    let modelDetails: { name: string; class: string };
    Object.keys(badges).forEach((key: string) => {
      if (String(modelName).includes(key) || String(tag) === key) {
        modelDetails = badges[key];
        // Override model name if LikelihoodToBuy or Lead Grade and model type pql2 or mql
        if (['likelihood', 'grade'].includes(key) && IS_HYBRID(model?.type)) {
          const modelType = model.type.replace('pql2', 'pql').toUpperCase();
          modelDetails.name =
            key === 'grade' ? `${modelDetails.name} - ${modelType}` : modelType;
        }
      }
    });

    return (
      <h4 className="d-inline-block mb-0">
        {modelDetails && (
          <span className={`badge ${modelDetails.class}`}>
            {modelDetails.name}
          </span>
        )}
      </h4>
    );
  }

  async saveAdminJson(
    object_type: string,
    tenant: number,
    jsonConfiguration: any
  ) {
    await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/metadata/push/${object_type}`,
      {
        ...jsonConfiguration,
      }
    );
  }

  handleDataMappingChange(
    value: string | null,
    connector: Integration,
    object: 'lead' | 'contact' | 'identify',
    name: string
  ) {
    const pushConfigurationCopy = cloneDeep(this.state.pushConfiguration);
    const pushContactConfiguration = this.getPushContactConfiguration(
      pushConfigurationCopy
    );

    const realObject = connector === 'salesforce' ? capitalize(object) : object;

    const attribute = pushContactConfiguration.attributes.find(
      ({ name: attributeName }) => attributeName === name
    );

    if (attribute.integrations[connector]) {
      // @ts-ignore
      attribute.integrations[connector][realObject] = value;
    } else {
      attribute.integrations = {
        ...attribute.integrations,
        [connector]: {
          [realObject]: value,
        },
      };
    }
    this.setState({ pushConfiguration: pushConfigurationCopy });
  }

  handleModelSelection(e: any) {
    this.setState({
      pickedModel: {
        ...this.state.pickedModel,
        [e.target.id]: e.target.checked,
      },
    });
  }

  checkOnConfirm(object: { [key: string]: boolean }) {
    return object && Object.values(object).some((val) => val === true);
  }

  handleConnectorSelection(e: any) {
    const updatedConnectors = {
      ...this.state.connectors,
      [e.target.id]: e.target.checked,
    };

    this.setState((prevState: PushConfigurationState) => ({
      connectors: updatedConnectors,
      basePushConfig: {
        has_customer_fit: this.state.pickedModel.customerFit,
        has_likelihood_to_buy: this.state.pickedModel.likelihoodToBuy,
        has_lead_grade: this.state.pickedModel.leadGrade,
        has_aggregations: this.state.pickedModel.aggregations,
        has_computations: this.state.pickedModel.computations,
      },
      pushConfiguration: prevState.pushConfiguration.map(
        (pushItem: PushConfigurationItem) => {
          const newPushItem = { ...pushItem };
          if (newPushItem.configuration)
            newPushItem.configuration.connectors = updatedConnectors;
          else
            newPushItem.configuration = {
              connectors: updatedConnectors,
            } as any;
          return newPushItem;
        }
      ),
    }));
  }

  handleDatePick(e: any, connector: string) {
    this.setState({
      datePicked: {
        ...this.state.datePicked,
        [connector]: {
          ...this.state.datePicked[connector],
          score_contacts_created_since: e.target.value,
        },
      },
    });
  }

  handleTimeLimitCheck(e: any, connector: string) {
    this.setState({
      datePicked: {
        ...this.state.datePicked,
        [connector]: {
          ...this.state.datePicked[connector],
          limit_scoring_timeframe: e.target.checked,
        },
      },
    });
  }

  handleLikelihoodToBuyChange(ltbModels: number[]) {
    this.setState({ ltbModels });
  }

  handleLeadGradeChange(lgModels: number[]) {
    this.setState({ lgModels });
  }

  handleAggregationsChange(aggregations: string[]) {
    this.setState({
      aggregationsFields: aggregations,
    });
  }

  handleComputationsChange(computations: string[]) {
    this.setState({
      computationsFields: computations,
    });
  }

  handleTextAreaAdmin(e: any, index: number) {
    const pushConfigurationCopy = cloneDeep(this.state.pushConfiguration);
    pushConfigurationCopy[index].attributes = JSON.parse(e.target.value);
    this.setState({
      pushConfiguration: pushConfigurationCopy,
    });
  }

  handleChangeObject(e: any, connector: string) {
    this.setState({
      pickedObject: {
        ...this.state.pickedObject,
        [connector]: {
          ...this.state.pickedObject[connector],
          [e.target.id]: e.target.checked,
        },
      },
    });
  }

  handleScoresChangeLtb(name: string, value: number) {
    this.setState({
      roundLtbYaml: {
        ...this.state.roundLtbYaml,
        [name]: value,
      },
    });
  }

  handleScoresChangeLg(name: string, value: number) {
    this.setState({
      roundLgYaml: {
        ...this.state.roundLgYaml,
        [name]: value,
      },
    });
  }

  yamlAggregationNames() {
    return this.state.aggregationsFields.map((value: string) => ({
      aggregation_name: value,
    }));
  }

  yamlComputationsNames() {
    return this.state.computationsFields.map((value: string) => ({
      computation_name: value,
    }));
  }

  handleYamlPickedModel(e: any) {
    this.setState({
      yamlPickedModel: {
        ...this.state.yamlPickedModel,
        [e.target.id]: e.target.checked,
      },
    });
  }

  onConfirmStepModel() {
    this.setState({ dataModelFormHasBeenSubmitted: true });
    const { pickedModel } = this.state;
    const activeModels = Object.keys(pickedModel).filter((k) => pickedModel[k]);

    if (this.state.hybridEnabled)
      if (
        (activeModels.includes('likelihoodToBuy') &&
          !this.state.ltbModels.length) ||
        (activeModels.includes('leadGrade') && !this.state.lgModels.length)
      ) {
        return;
      }

    FIELD_NAMES.aggregations.push(...this.state.aggregationsFields);
    FIELD_NAMES.computations.push(...this.state.computationsFields);
    const cleanedAggregationsFields = uniq(FIELD_NAMES.aggregations);
    const cleanedComputationsFields = uniq(FIELD_NAMES.computations);
    FIELD_NAMES.aggregations = cleanedAggregationsFields;
    FIELD_NAMES.computations = cleanedComputationsFields;

    const attributesMap = new Map();
    const pushConfigurationCopy = cloneDeep(this.state.pushConfiguration);
    const pushContactConfiguration = this.getPushContactConfiguration(
      pushConfigurationCopy
    );

    if (pushContactConfiguration.attributes) {
      pushContactConfiguration.attributes.forEach((attribute) => {
        attributesMap.set(attribute.name, attribute);
      });
    }

    const newAttributes = activeModels
      .map((model) => {
        // We want to have n times model fields as models to push for LTB and LG
        if (['likelihoodToBuy', 'leadGrade'].includes(model)) {
          return this.state[
            model === 'likelihoodToBuy' ? 'ltbModels' : 'lgModels'
          ]
            .map((aModel) => {
              const theModel = this.state.models.find(
                ({ modelId }) => modelId === aModel
              );
              return FIELD_NAMES[model].map((field: string) => {
                const name = IS_HYBRID(theModel.type)
                  ? `${field}_${theModel.modelId}`
                  : field;
                return (
                  attributesMap.get(name) ?? {
                    ...JSON_OBJECT,
                    name,
                    batch: true,
                    realtime: false,
                  }
                );
              });
            })
            .flat();
        }
        return FIELD_NAMES[model].map((field: string) => {
          if (model === 'aggregations') {
            return (
              attributesMap.get(field) ?? {
                ...JSON_OBJECT,
                tag: 'aggregation',
                name: field,
                batch: true,
                realtime: false,
                type: 'VARCHAR(256)',
              }
            );
          }
          if (model === 'computations') {
            return (
              attributesMap.get(field) ?? {
                ...JSON_OBJECT,
                tag: 'computation',
                name: field,
                batch: true,
                realtime: false,
                type: 'VARCHAR(256)',
              }
            );
          }
          // customerFit
          return (
            attributesMap.get(field) ?? {
              ...JSON_OBJECT,
              name: field,
              batch: true,
              realtime: true,
            }
          );
        });
      })
      .flat();

    pushContactConfiguration.attributes = newAttributes;

    this.setState({
      pushConfiguration: pushConfigurationCopy,
    });

    this.changePageState('connector');
  }

  onConfirmConnectors() {
    // Update connectors in integrations attributes rows
    const pushConfiguration = cloneDeep(this.state.pushConfiguration);
    const pushItem = this.getPushContactConfiguration(pushConfiguration);

    // Remove unnecessary connectors in attributes
    pushItem.attributes.map((item: JsonObject) => {
      const newItem = { ...item };
      Object.keys(pushItem.configuration.connectors).forEach(
        (key: keyof IntegrationsConfig) => {
          if (
            !pushItem.configuration.connectors[key] &&
            newItem.integrations[key]
          ) {
            delete newItem.integrations[key];
          }
        }
      );
      return newItem;
    });

    this.setState({ pushConfiguration });
    this.changePageState('dataMapping');
  }

  connectorActiveArray(integrationList: ActiveIntegrationDataModel[]) {
    const connectors = [];
    const hasSalesforce = hasConnectorActive(integrationList, 'salesforce');
    const hasHubspot = hasConnectorActive(integrationList, 'hubspot');
    const hasIntercom = hasConnectorActive(integrationList, 'intercom');
    const hasSegment = hasConnectorActive(integrationList, 'segment');

    if (hasSalesforce) {
      connectors.push('salesforce');
    }
    if (hasHubspot) {
      connectors.push('hubspot');
    }
    if (hasIntercom) {
      connectors.push('intercom');
    }
    if (hasSegment) {
      connectors.push('segment');
    }

    return connectors;
  }

  async checkIfConfigIsCustom() {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${this.props.tenant}/metadata/push/check_config`
    );
    return data.isCustom;
  }

  isDateValid() {
    try {
      Object.entries(this.state.connectors).forEach(
        ([connector, connectorChecked]) => {
          if (connector === 'intercom') {
            return true;
          }
          if (connector === 'segment') {
            if (connectorChecked) {
              if (
                this.state.datePicked.segment.limit_scoring_timeframe &&
                !this.state.datePicked.segment
                  .score_contacts_active_last_X_days_segment
              ) {
                throw new Error(`Missing range input for ${connector}`);
              }
              if (
                Number(
                  this.state.datePicked.segment
                    .score_contacts_active_last_X_days_segment || 0
                ) > 90 ||
                Number(
                  this.state.datePicked.segment
                    .score_contacts_active_last_X_days_segment || 0
                ) < 1
              ) {
                throw new Error(
                  `Date range must be between 1-90 for ${connector}`
                );
              }
              return true;
            }
          }
          if (connectorChecked) {
            if (
              this.state.datePicked &&
              this.state.datePicked[connector].limit_scoring_timeframe &&
              !this.state.datePicked[connector].score_contacts_created_since
            ) {
              throw new Error(`Missing date input for ${connector}`);
            }
            return true;
          }
        }
      );
    } catch (e) {
      return false;
    }
    return true;
  }

  async onConfirmDataMapping() {
    if (!this.isDateValid()) {
      await Swal.fire({
        title: 'Missing/invalid dates',
        text: 'Please specify check for missing/invalid dates',
      });
      return;
    }

    // Update objects in connectors
    const pushConfiguration = cloneDeep(this.state.pushConfiguration);
    const pushItem = this.getPushContactConfiguration(pushConfiguration);

    // Remove unnecessary objects in connectors
    pushItem.attributes.map((item: JsonObject) => {
      const newItem = { ...item };
      Object.keys(newItem.integrations).forEach(
        (connector: keyof IntegrationsConfig) => {
          if (connector !== 'all') {
            Object.keys(newItem.integrations[connector]).forEach(
              (object: 'Lead' | 'Contact') => {
                const pickedObject = this.state.pickedObject as any;
                if (pickedObject[connector][object.toLowerCase()] === false) {
                  delete newItem.integrations[connector][object];
                }
                // If there is no more object in the connector, remove the connector object
                if (Object.keys(newItem.integrations[connector]).length === 0) {
                  delete newItem.integrations[connector];
                }
              }
            );
          }
        }
      );
      return newItem;
    });

    pushItem.configuration.objects = cloneDeep(this.state.pickedObject);

    this.setState({
      basePushConfig: {
        has_customer_fit: this.state.pickedModel.customerFit,
        has_likelihood_to_buy: this.state.pickedModel.likelihoodToBuy,
        has_lead_grade: this.state.pickedModel.leadGrade,
        has_computations: this.state.pickedModel.computations,
        has_aggregations: this.state.pickedModel.aggregations,
      },
      pushConfiguration,
    });
    this.changePageState('review');
  }

  async launchConfirmationSwal(forceUpdate: Function) {
    const rawConfiguration = this.getPushContactConfiguration(
      this.state.pushConfiguration
    );

    rawConfiguration.configuration.models = this.state.pickedModel;
    rawConfiguration.configuration.objects = this.state.pickedObject;
    rawConfiguration.configuration.connectors = this.state.connectors;
    rawConfiguration.configuration.timeframe = this.state.datePicked;
    rawConfiguration.configuration.roundLg = this.state.roundLgYaml;
    rawConfiguration.configuration.roundLtb = this.state.roundLtbYaml;

    const pickedConnectors: string[] = Object.keys(
      rawConfiguration.configuration.connectors
    ).filter((k: any) => rawConfiguration.configuration.connectors[k]);
    const cleanConfiguration: PushConfigurationItem = {
      ...rawConfiguration,
      attributes: rawConfiguration.attributes.map((el) => ({
        ...el,
        integrations: removeNullish(el.integrations),
      })),
    };
    await Swal.fire({
      title: 'Start pushing scores to your CRM?',
      text:
        'Once triggered, the MadKudu fields in your CRM will start updating within the next 8 hours. Please make sure to review your configuration before triggering the update.',
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      allowOutsideClick: () => !Swal.isLoading(),
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Trigger update',
      preConfirm: async () => {
        await Promise.all([
          saveJson(this.props.tenant, cleanConfiguration),
          updateExportContacts(this.props.tenant, pickedConnectors),
        ]);
        const modelTypes = new Map(
          this.state.models.map(({ modelId, type }) => [modelId, type])
        );
        window?.analytics?.track('Push configuration published', {
          tenant: this.props.tenant,
          email: this.props.userEmail,
          connectors: Object.entries(
            this.getPushContactConfiguration(this.state.pushConfiguration)
              ?.configuration.connectors || {}
          )
            .filter(([, value]) => value)
            .map(([key]) => key),
          customerFitModels: this.state.models
            .filter(({ type }) => type === 'customer_fit')
            .map(({ modelId }) => modelId),
          accountEngagementModels: this.state.models
            .filter(({ type }) => type === 'mqa')
            .map(({ modelId }) => modelId),
          leadGradeModels: this.state.lgModels,
          leadGradePqlModels: this.state.lgModels.filter(
            (id) => modelTypes.get(id) === 'pql2'
          ),
          leadGradeMqlModels: this.state.lgModels.filter(
            (id) => modelTypes.get(id) === 'mql'
          ),
          leadEngagementModels: this.state.ltbModels,
          lltbModels: this.state.ltbModels.filter(
            (id) => modelTypes.get(id) === 'pql'
          ),
          ltbPqlModels: this.state.ltbModels.filter(
            (id) => modelTypes.get(id) === 'pql2'
          ),
          ltbMqlModels: this.state.ltbModels.filter(
            (id) => modelTypes.get(id) === 'mql'
          ),
          models: this.state.models.map(({ modelId }) => modelId),
        });
        await slackNotification(
          this.props.tenant,
          'push',
          `Tenant ${this.props.tenant} has been configurated through the push config UI by ${this.props.userEmail}`,
          'C01QQRWQZ4K'
        );
      },
    }).then(async () => {
      // eslint-disable-next-line
      Swal.fire({
        title: 'Configuration saved',
        text:
          'The MadKudu fields in your CRM will update within the next 8 hours.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
      }).then(async (res) => {
        if (res.isConfirmed) {
          this.changePageState('overview');
          await forceUpdate();
        }
      });
    });
  }

  renderAdminView() {
    return (
      <AdminPushConfiguration
        handleTextAreaAdmin={this.handleTextAreaAdmin}
        pushConfiguration={this.state.pushConfiguration}
        saveAdminJson={this.saveAdminJson}
        tenant={this.props.tenant}
      />
    );
  }

  getPushContactConfiguration(
    pushConfiguration: PushConfigurationItem[]
  ): PushConfigurationItem {
    return (
      pushConfiguration.find(
        ({ object_type }: { object_type: string }) =>
          object_type === 'contact_update'
      ) || { object_type: 'contact_update', attributes: [] }
    );
  }

  renderReviewYamlJson() {
    return (
      <React.Fragment>
        <ReviewYamlJson
          pushConfigurationItem={this.getPushContactConfiguration(
            this.state.pushConfiguration
          )}
          tenant={this.props.tenant}
        />
        <div className="row mt-4 mb-4">
          <div className="col-6 offset-5">
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => this.changePageState('review')}
            >
              Go back
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderDataModel() {
    return (
      <React.Fragment>
        <DataModel
          tenant={this.props.tenant}
          modelsList={this.state.models}
          dataModelFormHasBeenSubmitted={
            this.state.dataModelFormHasBeenSubmitted
          }
          hasHybridModels={this.state.hasHybridModels}
          hybridEnabled={this.state.hybridEnabled}
          handleModelSelection={this.handleModelSelection}
          pickedModel={this.state.pickedModel}
          createArrayOfModels={this.createArrayOfModels}
          ltbModels={this.state.ltbModels}
          handleLikelihoodToBuyChange={this.handleLikelihoodToBuyChange}
          lgModels={this.state.lgModels}
          handleLeadGradeChange={this.handleLeadGradeChange}
          computationsFields={this.state.computationsFields}
          handleComputationsChange={this.handleComputationsChange}
          aggregationsFields={this.state.aggregationsFields}
          handleAggregationsChange={this.handleAggregationsChange}
          generateModelBadges={this.generateModelBadges}
        />

        <div className="row d-flex justify-content-center align-items-center m-2 ">
          <div className="col-2">
            <button
              className="btn btn-primary float-right"
              onClick={() => this.changePageState('overview')}
            >
              Go Back
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-primary"
              disabled={!this.checkOnConfirm(this.state.pickedModel)}
              onClick={() => {
                this.onConfirmStepModel();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderConnectorChoice(activeConnectors: string[]) {
    return (
      <React.Fragment>
        <ConnectorChoice
          handleConnectorSelection={this.handleConnectorSelection}
          connectors={this.state.connectors}
          activeConnectors={activeConnectors}
        />
        <div className="row d-flex justify-content-center align-items-center m-2 ">
          <div className="col-2">
            <button
              className="btn btn-primary float-right"
              onClick={() => {
                // this.setState({ jsonPushConfiguration: [] });
                this.changePageState('model');
              }}
            >
              Go Back
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-primary"
              disabled={!this.checkOnConfirm(this.state.connectors)}
              onClick={() => this.onConfirmConnectors()}
            >
              Confirm
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderReviewConfiguration() {
    const connectors = Object.keys(this.state.connectors).filter(
      (k) => this.state.connectors[k]
    );
    return (
      <React.Fragment>
        <ReviewConfiguration
          connectors={connectors}
          pickedObject={this.state.pickedObject}
          pushConfigurationItem={this.getPushContactConfiguration(
            this.state.pushConfiguration
          )}
          generateModelBadges={this.generateModelBadges}
          datePicked={this.state.datePicked}
          modelsList={this.state.models}
        />

        <div className="row d-flex justify-content-center align-items-center m-2">
          <div className="col-2">
            <button
              className="btn btn-primary float-right"
              onClick={() => this.changePageState('dataMapping')}
            >
              Go Back
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-primary"
              onClick={() => this.launchConfirmationSwal(this.reloadComponent)}
            >
              Start syncing
            </button>
          </div>
          <div className="col-3">
            <button
              className="btn btn-success"
              onClick={() => this.changePageState('reviewYamlJson')}
            >
              Review YAML and JSON
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { currentStep } = this.state;

    const contactUpdate = this.getPushContactConfiguration(
      this.state.pushConfiguration
    );

    const isPushConfigFromUi = !!contactUpdate?.configuration;

    const activeConnectors = this.connectorActiveArray(
      this.state.integrationList
    );

    return (
      <>
        <Navbar
          isSuperKudu={this.props.isSuperKudu}
          userCanEdit={this.props.userCanEdit}
          handleNavbarPageToggle={this.handleNavbarPageToggle}
          contactUpdateObject={contactUpdate}
        />
        {this.state.isLoading ? (
          <div className="container pt-4">
            <Loader />
          </div>
        ) : (
          <>
            {EDIT_CONFIGURATION_STEPS.includes(currentStep) && (
              <SubHeader pageName={currentStep} />
            )}
            {this.props.toggleView(currentStep)}
            <div className="container">
              {currentStep === OVERVIEW_STEP && (
                <Overview
                  modelsList={this.state.models}
                  isPushConfigFromUi={isPushConfigFromUi}
                  isCustomPushConfig={this.state.isCustomPushConfig}
                  userCanEdit={this.props.userCanEdit}
                  changePageState={this.changePageState}
                  pushConfigurationItem={contactUpdate}
                  generateModelBadges={this.generateModelBadges}
                  isSuperKudu={this.props.isSuperKudu}
                />
              )}
              {/* Start of push config UI components */}
              {currentStep === EDIT_VIEW_STEP && this.renderDataModel()}
              {currentStep === CONNECTOR_STEP &&
                this.renderConnectorChoice(activeConnectors)}
              {currentStep === DATA_MAPPING_STEP && (
                <DataMapping
                  modelsList={this.state.models}
                  tenant={this.props.tenant}
                  handleSegmentTimeFrameChange={
                    this.handleSegmentTimeFrameChange
                  }
                  connectors={this.state.connectors}
                  pickedModel={this.state.pickedModel}
                  handleDataMappingChange={this.handleDataMappingChange}
                  handleChangeObject={this.handleChangeObject}
                  pickedObject={this.state.pickedObject}
                  handleDatePick={this.handleDatePick}
                  datePicked={this.state.datePicked}
                  handleTimeLimitCheck={this.handleTimeLimitCheck}
                  pushConfigurationItem={contactUpdate}
                  generateModelBadges={this.generateModelBadges}
                  handleScoresChangeLtb={this.handleScoresChangeLtb}
                  handleScoresChangeLg={this.handleScoresChangeLg}
                  roundLtbYaml={this.state.roundLtbYaml}
                  roundLgYaml={this.state.roundLgYaml}
                  handleYamlPickedModel={this.handleYamlPickedModel}
                  yamlPickedModel={this.state.yamlPickedModel}
                  changePageState={this.changePageState}
                  onConfirmDataMapping={this.onConfirmDataMapping}
                />
              )}
              {currentStep === REVIEW_CONFIGURATION_STEP &&
                this.renderReviewConfiguration()}
              {currentStep === ADMIN_VIEW_STEP && this.renderAdminView()}
              {currentStep === REVIEW_YAML_STEP && this.renderReviewYamlJson()}
              {/* End of push config UI components */}
              {currentStep === FORCE_PUSH_STEP && (
                <ForcePush tenant={this.props.tenant} />
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
