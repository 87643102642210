import axios from 'axios';
import Backbone from 'backbone';
import uniq from 'lodash/uniq';
import app from '../../app';
import { isIntegrationPullActive, isIntegrationPushActive } from '../is_active';
import FieldDataModel from '../../mapping/models/FieldDataModel';

const template = require('./fields_mapping.pug');

function cleanPullConfigData(
  data: Record<string, { attributes: FieldDataModel[] }>
) {
  return Object.entries(data).map(([key, item]) => ({
    title: key,
    fields: item.attributes,
  }));
}

function parsePushFields(data: UnknownObject) {
  const rows: UnknownObject[] = [];
  const dataKeys = Object.keys(data);

  // get list of all different fields (lead, contact, persons, etc)
  const rawValueTypes = dataKeys.reduce(
    (acc, mkField) => acc.concat(...Object.keys(data[mkField])),
    []
  );
  const allValueTypes = ['MadKudu', ...uniq(rawValueTypes)];

  dataKeys.forEach((mkField) => {
    const partialRow = Object.keys(data[mkField]).reduce(
      (acc: UnknownObject, valueType) => {
        acc[valueType] = data[mkField][valueType];
        return acc;
      },
      {}
    );
    rows.push({
      MadKudu: mkField,
      ...partialRow,
    });
  });

  return {
    rows,
    allValueTypes,
  };
}

function cleanPushConfigData(data: UnknownObject) {
  const { Contact: rawContact, Account: rawAccount } = data;

  // if no data
  if (!Object.keys(rawContact).length && !Object.keys(rawAccount).length) {
    return [];
  }

  const cleanedData = [
    {
      title: 'Contact',
      ...parsePushFields(rawContact),
    },
  ];

  // if no data under "account"
  if (!Object.keys(rawAccount).length) return cleanedData;

  cleanedData.push({
    title: 'Account',
    ...parsePushFields(rawAccount),
  });

  return cleanedData;
}

async function getPullConfigForTenantAndIntegration(
  tenantId: number,
  integration: string
) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}/pull?ignore=true`
  );
  return cleanPullConfigData(data.config);
}

async function getPushConfigForTenantAndIntegration(
  tenantId: number,
  integration: string
) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}/push`
  );
  return cleanPushConfigData(data);
}

async function getPushFilter(tenantId: number, integration: string) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}/push_filter`
  );
  if (typeof data !== 'string') return '';
  return data;
}

async function setPushFilter(
  tenantId: number,
  integration: string,
  pushFilter: string
) {
  await axios.put(
    `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}/push_filter`,
    { pushFilter }
  );
}

function isPullPage() {
  const splitUrl = window.location.href.split('/');
  return splitUrl[splitUrl.length - 1] === 'pull';
}

function isPushPage() {
  const splitUrl = window.location.href.split('/');
  return splitUrl[splitUrl.length - 1] === 'push';
}

export default Backbone.View.extend({
  events: {
    'click #pullToggle': 'toggleView',
    'click #pushToggle': 'toggleView',
    'submit #pushFilterForm': 'onSubmitPushFilterForm',
    'click .btn-configure': 'goPullConfig',
    'click #go-to-salesforce-config-button': 'goToSalesforceConfigure',
  },

  async initialize(options: {
    tenant: number;
    integration: string;
    displayPush: boolean;
    credentialsFound: boolean;
  }) {
    this.isUserAdmin = app.session.get('user').role === 'admin';
    this.userEmail = app.session.get('user').email;
    this.tenant = options.tenant;
    this.integration = options.integration;
    this.displayPush = options.displayPush;
    this.credentialsFound = options.credentialsFound;
    this.pushConfigData = await getPushConfigForTenantAndIntegration(
      this.tenant,
      this.integration
    );
    this.isAccountConfigured = !!this.pushConfigData.filter((config: any) => {
      return config.title === 'Account';
    }).length;
    this.integrationPullActive = await isIntegrationPullActive(
      this.tenant,
      this.integration
    );
    this.integrationPushActive = await isIntegrationPushActive(
      this.tenant,
      this.integration
    );
    this.pushFilter = await getPushFilter(this.tenant, this.integration);
    this.pullConfigData = await getPullConfigForTenantAndIntegration(
      this.tenant,
      this.integration
    );

    this.showAdminFields();

    this.render();
  },

  showAdminFields() {
    if (app.session.get('user').superKudu) {
      // Shows all 4 default fields if user is a superkudu
      const fields = ['Lead', 'Contact', 'Opportunity', 'Account'];

      const currFields: UnknownObject = []; // Stores which fields are currently defined
      this.pullConfigData.forEach((field: any) => currFields.push(field.title));

      // eslint-disable-next-line
      for (const field of fields) {
        // Adding fields with null values if they don't exist
        if (!currFields.includes(field)) {
          this.pullConfigData.push({ title: field, fields: [] });
        }
      }
    }
  },

  toggleView(event: any) {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${
        this.integration
      }/${event.currentTarget.innerText.toLowerCase()}`
    );
    this.render();
  },

  async onSubmitPushFilterForm(e: any): Promise<any> {
    e.preventDefault();
    if (!this.isUserAdmin) return null;
    const button = e.target.children[2];
    button.disabled = true;
    button.innerText = 'Saving';
    const text = (document.getElementById(
      'pushFilterText'
    ) as HTMLTextAreaElement).value;
    await setPushFilter(this.tenant, this.integration, text);

    // empty timeout to avoid having the button change back too soon
    setTimeout(() => {}, 800);

    this.pushFilter = await getPushFilter(this.tenant, this.integration);
    this.render();
  },

  render() {
    let displayPull = isPullPage();
    let displayPush = isPushPage();
    const { integrationPullActive, integrationPushActive } = this;

    if (!displayPull && !displayPush) {
      if (integrationPushActive) displayPush = true;
      if (integrationPullActive && !integrationPushActive) displayPull = true;
    }

    const templateRendered = template({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound: this.credentialsFound,
      pullConfigData: this.pullConfigData,
      pushConfigData: this.pushConfigData,
      pushFilter: this.pushFilter,
      isUserAdmin: this.isUserAdmin,
      isAccountConfigured: this.isAccountConfigured,
      integrationPullActive,
      integrationPushActive,
      displayPull,
      displayPush,
    });
    const html = $('#tpl_main', templateRendered).html();
    this.$el.html(html);
    return this;
  },

  // Pull configuration modification & ticket
  goToSalesforceConfigure() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}/push/configure`,
      true
    );
  },
  goPullConfig(event: any) {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${
        this.integration
      }/pull/${event.target.id.replace('btn-', '')}`,
      true
    );
  },
});
