import React from 'react';
import { Button, Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { DATASOURCE_OBJECTS_ATTRIBUTE } from '../../../utils';
import { unslugFieldName } from '../../../pullConfigManager';
import { AttributeMappingData } from '../../models/AttributeMappingData';
import AttributeMappingManager from '../../models/AttributeMappingManager';
import { AttributeMappingConnectorsNames } from '../../../../types';
import MissingFieldMicrocopy from '../../../../components/ErrorMicroCopyComponent';
import { OBJECT_PLACEHOLDER_VALUE } from '../../../constants';
import { beautifyComputationName } from './utils';

type AttributeFormProps = {
  connector: AttributeMappingConnectorsNames;
  attributeMappingManager: AttributeMappingManager;
  tenant: number;
  isEditClicked: boolean;
  createNewAttribute: (connector: AttributeMappingConnectorsNames) => void;
  handleTableChange: (
    connector: AttributeMappingConnectorsNames,
    formIndex: number,
    newTable: string
  ) => void;
  handleFieldNameChange: (
    connector: AttributeMappingConnectorsNames,
    formIndex: number,
    newField: string
  ) => void;
  handleFieldNameDisplayChange: (
    connector: AttributeMappingConnectorsNames,
    formIndex: number,
    newFieldName: string
  ) => void;
  handleRemoveAttribute: (
    connector: AttributeMappingConnectorsNames,
    formIndex: number
  ) => void;
};

type AttributeFormState = {
  attributeMappingData: AttributeMappingData;
};

export default class AttributeFormComponent extends React.Component<
  AttributeFormProps,
  AttributeFormState
> {
  cleanedDatasourceName: string;

  constructor(props: AttributeFormProps) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleFieldNameChange = this.handleFieldNameChange.bind(this);
    this.handleFieldNameDisplayChange = this.handleFieldNameDisplayChange.bind(
      this
    );
    this.handleRemoveAttribute = this.handleRemoveAttribute.bind(this);
    this.createNewAttribute = this.createNewAttribute.bind(this);
    this.handleComputationNameOnClick = this.handleComputationNameOnClick.bind(
      this
    );
    this.cleanedDatasourceName =
      props.connector[0].toUpperCase() + props.connector.slice(1);

    this.state = {
      attributeMappingData: this.props.attributeMappingManager.data,
    };
  }

  createNewAttribute() {
    this.props.createNewAttribute(this.props.connector);
  }

  handleTableChange(formIndex: number, newTable: string) {
    this.props.handleTableChange(this.props.connector, formIndex, newTable);
  }

  handleFieldNameChange(formIndex: number, newField: string) {
    this.props.handleFieldNameChange(this.props.connector, formIndex, newField);
  }

  handleFieldNameDisplayChange(formIndex: number, newFieldName: string) {
    this.props.handleFieldNameDisplayChange(
      this.props.connector,
      formIndex,
      newFieldName
    );
  }

  handleRemoveAttribute(formIndex: number) {
    this.props.handleRemoveAttribute(this.props.connector, formIndex);
  }

  handleComputationNameOnClick(tenant: number, computationName: string) {
    window.open(
      `https://studio.madkudu.com/tenant/${tenant}/computations/${computationName}`,
      '_blank'
    );
  }

  getContentOfReactToolTip(
    dataAsString: string | null,
    handleComputationNameOnClick: (
      tenant: number,
      computationName: string
    ) => void
  ) {
    if (dataAsString === null) {
      return;
    }
    const data: {
      tenant: number;
      usedInComputations: string[] | null;
    } = JSON.parse(dataAsString);
    return (
      <div>
        This attribute cannot be edited or deleted because it is used in the
        computation{' '}
        {data.usedInComputations?.map((computation, index) => (
          <>
            <span className="link font-weight-bold">
              <u
                onClick={() =>
                  handleComputationNameOnClick(data.tenant, computation)
                }
              >
                {beautifyComputationName(computation)}
              </u>
            </span>
            {data.usedInComputations.length - 1 !== index ? ', ' : ''}
          </>
        ))}
      </div>
    );
  }

  render() {
    const {
      connector,
      attributeMappingManager,
      isEditClicked,
      tenant,
    } = this.props;
    const { isReadOnly } = attributeMappingManager;
    const { attributeMappingData } = this.state;
    const IS_SALESFORCE = connector === 'salesforce';
    const filterForm = attributeMappingData.getFilterForm(connector);

    return (
      <div className="p-2">
        <h3 className="mb-3">Attributes</h3>
        <Row>
          <Col sm={12}>
            <div>
              <Row>
                <Col md={2} className="text-center">
                  <strong>Object</strong>
                </Col>
                <Col md={5} className="text-center">
                  <strong>Field Name</strong>
                </Col>
                <Col md={4} className="text-center">
                  <strong>Attribute Field Name</strong>
                </Col>
              </Row>
              {filterForm?.forms?.map(
                (
                  { attributeFieldName, field, usedInComputations, table },
                  formIndex
                ) => {
                  return (
                    <FormGroup key={`form_group_${formIndex}`}>
                      <Row
                        key={formIndex}
                        data-for="attribute-mapping-tooltip"
                        data-tip={
                          usedInComputations?.length
                            ? JSON.stringify({ tenant, usedInComputations })
                            : null
                        }
                      >
                        <Col md={2}>
                          <Select
                            isDisabled={
                              isReadOnly ||
                              !isEditClicked ||
                              !!usedInComputations?.length
                            }
                            value={{
                              label: table,
                              value: table,
                            }}
                            options={DATASOURCE_OBJECTS_ATTRIBUTE[
                              connector
                            ].map((value) => ({
                              label: value,
                              value,
                            }))}
                            onChange={({ value }) =>
                              this.handleTableChange(formIndex, value)
                            }
                          />
                          {attributeMappingData.hasErrorOnProperty(
                            connector,
                            formIndex,
                            'table'
                          ) && (
                            <MissingFieldMicrocopy microcopy="Please select a valid table value." />
                          )}
                        </Col>
                        <Col md={5}>
                          <Select
                            isDisabled={
                              isReadOnly ||
                              !isEditClicked ||
                              table === OBJECT_PLACEHOLDER_VALUE ||
                              !!usedInComputations?.length
                            }
                            isSearchable
                            value={{
                              value: field,
                              label: unslugFieldName(field, IS_SALESFORCE),
                            }}
                            options={filterForm
                              .getFields(table)
                              ?.map(({ name }) => ({
                                value: name,
                                label: unslugFieldName(name, IS_SALESFORCE),
                              }))}
                            onChange={({ value }) =>
                              this.handleFieldNameChange(formIndex, value)
                            }
                          />
                          {attributeMappingData.hasErrorOnProperty(
                            connector,
                            formIndex,
                            'field'
                          ) && (
                            <MissingFieldMicrocopy microcopy="Please select a valid field value." />
                          )}
                        </Col>
                        <Col md={4}>
                          <FormControl
                            name="fieldNameDisplay"
                            type="text"
                            placeholder={`${this.props.connector}_table_<your_field>`}
                            value={attributeFieldName}
                            readOnly={
                              isReadOnly ||
                              !isEditClicked ||
                              !!usedInComputations?.length
                            }
                            onChange={(e: any) =>
                              this.handleFieldNameDisplayChange(
                                formIndex,
                                e.target.value
                              )
                            }
                          />
                          {attributeMappingData.hasErrorOnProperty(
                            connector,
                            formIndex,
                            'attribute name duplicate'
                          ) && (
                            <MissingFieldMicrocopy microcopy="Duplicated attribute name, please insert a new name." />
                          )}
                        </Col>
                        <Col xs={1}>
                          <a
                            data-tip={
                              usedInComputations?.length
                                ? 'Delete attribute'
                                : null
                            }
                          >
                            <Button
                              bsStyle={
                                usedInComputations?.length
                                  ? 'secondary'
                                  : 'danger'
                              }
                              disabled={
                                isReadOnly ||
                                !isEditClicked ||
                                !!usedInComputations?.length
                              }
                              className="pull-right"
                              onClick={() =>
                                this.handleRemoveAttribute(formIndex)
                              }
                            >
                              <i
                                className={`fa fa-${
                                  usedInComputations?.length ? 'lock' : 'trash'
                                }`}
                              ></i>
                            </Button>
                          </a>
                        </Col>
                      </Row>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        className="link link-light"
                        effect="float"
                        clickable={true}
                        delayHide={500}
                        id="attribute-mapping-tooltip"
                        getContent={(dataAsString) =>
                          this.getContentOfReactToolTip(
                            dataAsString,
                            this.handleComputationNameOnClick
                          )
                        }
                        disable={!isEditClicked}
                      />
                    </FormGroup>
                  );
                }
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button
              bsStyle="primary"
              disabled={isReadOnly || !isEditClicked}
              onClick={() => this.createNewAttribute()}
            >
              New attribute
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
