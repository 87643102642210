import React from 'react';
import app from '../../../app';

type ConfigurationComponentProps = {
  enabled: boolean;
  setEnabled: Function;
  loading: boolean;
  connector: string;
  updateConnector: Function;
  tenant: number;
};

type ConfigurationComponentState = {};

export default class ConfigurationComponent extends React.Component<
  ConfigurationComponentProps,
  ConfigurationComponentState
> {
  superKudu = app.session.get('user').superKudu;

  render() {
    if (this.props.loading) return <div>Loading...</div>;

    return (
      <>
        {/* TODO: Activate this feature when de-anonymization is ready */}
        {false && (
          <div className="mb-4 d-flex align-items-center">
            <span className="mr-2">
              <b>Connector:</b>
            </span>
            <span>
              <select
                value={this.props.connector ?? ''}
                onChange={(newVal) =>
                  this.props.updateConnector(newVal.target.value)
                }
                className="border p-1 px-2"
              >
                <option value=""></option>
                <option value="amplitude">Amplitude</option>
                <option value="segment">Segment</option>
                <option value="madkudu">MadKudu tag</option>
              </select>
              <span className="text-secondary d-inline-block ml-2">
                Choose the connector you want to de-anonymize your visitors with
              </span>
            </span>
          </div>
        )}

        <div className="mb-4 d-flex align-items-center">
          <span className="mr-2">
            <b>Status:</b>
          </span>
          <div className="d-inline-block border rounded overflow-hidden">
            <span
              onClick={() => this.props.setEnabled(true)}
              className={`${
                this.props.enabled ? 'bg-success text-white' : ''
              } ${
                this.superKudu ? 'cursor-pointer' : 'cursor-not-allowed'
              } d-inline-flex align-items-center py-2 px-3`}
            >
              Enabled
            </span>
            <span
              onClick={() => this.props.setEnabled(false)}
              className={`${
                !this.props.enabled ? 'bg-success text-white' : ''
              } ${
                this.superKudu ? 'cursor-pointer' : 'cursor-not-allowed'
              }  d-inline-flex align-items-center py-2 px-3`}
            >
              Disabled
            </span>
          </div>
          <span className="text-secondary ml-2">
            (Only super Kudus can edit)
          </span>
        </div>

        <p className="mt-4">
          <strong>
            Account identification lets you know who are the visitors behind
            their IP address
          </strong>
        </p>
        <p className="mt-2 text-secondary">
          If set to "Enabled", you allow Madkudu to de-anonymize your visitor’s
          IP addresses using the Madkudu database. This feature is free if you
          activate data sharing in your settings, otherwise it leads to
          additional costs.
        </p>
        <a
          href="https://support.madkudu.com/hc/en-us/articles/17528123078797-Clearbit-Reveal-How-to-send-de-anonymized-web-traffic-to-MadKudu-via-Segment"
          target="_blank"
          rel="noopener noreferrer"
        >
          Already a Clearbit Reveal customer? Connect Clearbit to Segment{' '}
        </a>
      </>
    );
  }
}
