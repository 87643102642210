import axios from 'axios';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import copy from 'copy-to-clipboard';
import { detect } from 'detect-browser';
import {
  extractAllLiveAndEnabledEventsExceptConnector,
  getAllEnabledLiveEventsAllConnectors,
  getEvents,
} from '../event_mapping/utils';
import { publishConnectorsStates } from '../event_mapping/swal';
import ConnectorsNames from '../enums/ConnectorsNames';
import MappingsMode from '../enums/MappingsMode';
import { IndexedEvent } from '../event_mapping/components/functional/hooks/useEventMappingHistory';

const browser = detect();

export default class EventMappingMode {
  tenant: number;

  connector: ConnectorsNames;

  email: string;

  isCustom: boolean;

  isSuperKudu: boolean;

  allLiveAndEnabledEventsExceptActualConnector: any[];

  disabledConnectors: string[];

  constructor(
    tenant: number,
    connector: ConnectorsNames,
    email: string,
    isSuperKudu: boolean
  ) {
    this.tenant = tenant;
    this.connector = connector;
    this.email = email;
    this.isSuperKudu = isSuperKudu;
    this.isCustom = connector === 'madMl';
  }

  async init() {
    const { disabledConnectors } = await getEvents(this.tenant, this.connector);
    const allLiveAndEnabledEvents =
      (await getAllEnabledLiveEventsAllConnectors(
        this.tenant,
        disabledConnectors
      )) || [];
    this.allLiveAndEnabledEventsExceptActualConnector =
      (await extractAllLiveAndEnabledEventsExceptConnector(
        this.tenant,
        this.connector,
        allLiveAndEnabledEvents,
        disabledConnectors
      )) || [];
    this.disabledConnectors = disabledConnectors;
  }

  async preparePreviewData(actualConnectorEvent: Event[]): Promise<string> {
    try {
      const combinedEvents = this.allLiveAndEnabledEventsExceptActualConnector.concat(
        actualConnectorEvent
      );

      // Send to server and store in data
      const { data: yaml } = await axios.post(
        `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/events/preview`,
        {
          events: combinedEvents,
          disabledConnectors: this.disabledConnectors,
          connector: this.connector,
        }
      );
      return yaml;
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  async launchCopySwal(actualConnectorConfigs: any[]) {
    if (browser.name === 'firefox') {
      await Swal.fire({
        title: 'Error',
        text:
          'We are sorry, but this feature is not available on Firefox, please try with Google Chrome...',
      });
    } else {
      try {
        await Swal.fire({
          title: 'Copy yaml',
          text: 'Copy resulting yaml to clipboard?',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          showLoaderOnConfirm: true,
          confirmButtonText: 'Yes',
          preConfirm: async () => {
            Swal.showLoading();
            const yaml = await this.preparePreviewData(actualConnectorConfigs);
            copy(yaml);
            await Swal.fire({
              title: 'Copy yaml',
              text: 'Copied!',
              icon: 'success',
            });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      } catch (err) {
        await Swal.fire({
          title: 'Error',
          text: 'Copy failed, something went wrong...',
          icon: 'error',
        });
      }
    }
  }

  updateDisabledConnectors() {
    // remove disabled connector when publishing
    this.disabledConnectors = this.disabledConnectors.filter(
      (connector) => connector !== this.connector
    );
    if (!this.disabledConnectors.includes('madMl')) {
      this.disabledConnectors.push('madMl');
    }
  }

  async preparePublishData(
    actualConnectorEvent: IndexedEvent[] | string
  ): Promise<void> {
    await this.save(actualConnectorEvent);

    this.updateDisabledConnectors();
  }

  async publish(
    actualConnectorEvent: IndexedEvent[] | string,
    defaultMappingsMetadata: Record<string, unknown>,
    isCreation: boolean
  ): Promise<void> {
    const combinedEvents = [
      ...actualConnectorEvent,
      ...this.allLiveAndEnabledEventsExceptActualConnector,
    ].filter((event: Event) => event);
    // save "draft" events then publish & update the disabled connectors
    await this.preparePublishData(actualConnectorEvent);
    // send publish request PUT
    await axios.put(
      `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/events/${this.connector}`,
      {
        events: combinedEvents,
        disabledConnectors: this.disabledConnectors,
        defaultMappingsMetadata,
      }
    );
    window.analytics.track(`${isCreation ? 'Create new' : 'Publish'} mapping`, {
      mode: 'simple',
      map: `event - ${this.connector}`,
      tenant: this.tenant,
      email: this.email,
    });
  }

  async publishToggledConnector(
    disabledConnectors: string[],
    madMlSqlQuery?: string
  ): Promise<void> {
    this.disabledConnectors = disabledConnectors;

    await publishConnectorsStates(
      this.tenant,
      this.disabledConnectors,
      madMlSqlQuery,
      this.isCustom
    );
  }

  async save(configToSave: any) {
    const objectPropToSave = this.isCustom
      ? { madMlSqlQuery: configToSave }
      : { events: configToSave };
    await axios.put(
      `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/events/${this.connector}/saved`,
      {
        connector: this.connector,
        email: this.email,
        ...objectPropToSave,
      }
    );
  }

  async launchPublishSwal(
    actualConnectorConfigs: IndexedEvent[] | string,
    isCreation: boolean,
    reloadRelevantData: Function,
    reloadScriptCheckerState: Function
  ) {
    let swalOptions: SweetAlertOptions = {
      title: 'Publish event mapping?',
      html: this.disabledConnectors.includes('madMl')
        ? 'If you have a Likelihood to Buy model pushed to your integration, publishing your changes may update the Likelihood to Buy score and signals. <a href="https://support.madkudu.com/hc/en-us/articles/7164927648397" target="_blank">Here is a sanity checklist we suggest before publishing your edits.</a>'
        : 'This will overwrite entirely the existing madML configuration and replace it with this configuration. Make sure of your edits before publishing',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Publish mapping',
      cancelButtonText: 'Cancel',
    };

    if (this.isSuperKudu && ['hubspot', 'marketo'].includes(this.connector)) {
      swalOptions = {
        ...swalOptions,
        input: 'checkbox',
        inputPlaceholder:
          'Define as default event mappings for all new tenants',
      };
    }

    const { value: setAsDefaultMapping, isConfirmed } = await Swal.fire(
      swalOptions
    );

    const defaultMappingsMetadata: Record<string, unknown> = {
      isDefaultMappings: false,
      type: MappingsMode.event,
      associatedConnector: this.connector,
    };

    if (isConfirmed) {
      if (this.isSuperKudu && setAsDefaultMapping === 1) {
        const { value: associatedPackage } = await Swal.fire({
          title: 'Package?',
          text: 'Select the associated package',
          input: 'select',
          inputOptions: { default: 'default' },
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
        });
        defaultMappingsMetadata.associatedPackage = associatedPackage;
        defaultMappingsMetadata.isDefaultMappings = true;
      }

      Swal.fire({
        title: 'Publishing in progress',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });

      Swal.showLoading();

      try {
        await this.publish(
          actualConnectorConfigs,
          defaultMappingsMetadata,
          isCreation
        );

        const res = await Swal.fire({
          icon: 'success',
          title: 'Event mapping submitted!',
          text:
            'You’ll receive a confirmation email when the mapping has been processed.',
          confirmButtonText: 'Ok',
        });
        if (res.isConfirmed || res.isDismissed || res.isDenied) {
          await reloadScriptCheckerState();
          await reloadRelevantData();
        }
      } catch (err) {
        await Swal.fire({
          icon: 'error',
          title: `Request failed: ${err}!`,
        });
      }
    }
  }
}
