import axios from 'axios';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UserRoles } from '../../types';
import {
  TeamServiceModel,
  TeamUpsertServiceModel,
  UserServiceModel,
  teamSchema,
  teamsSchema,
} from './types';

export async function getUsers(tenant: number): Promise<UserServiceModel[]> {
  const { data } = await axios.get<UserServiceModel[]>(
    `${BONGO_URL}/v1/org/${tenant}/users`
  );
  return data;
}

export async function getTeams(tenant: number) {
  const { data } = await axios.get(`${BONGO_URL}/v1/org/${tenant}/teams`);
  return teamsSchema.parse(data);
}

export async function updateUserTeam({
  tenant,
  userId,
  teamId,
}: {
  tenant: number;
  userId: string;
  teamId: string;
}) {
  return axios.put(`${BONGO_URL}/v1/org/${tenant}/users/${userId}`, {
    team: teamId,
  });
}

export async function createTeam({
  tenant,
  team,
}: {
  tenant: number;
  team: TeamUpsertServiceModel;
}) {
  const { data } = await axios.post<TeamServiceModel>(
    `${BONGO_URL}/v1/org/${tenant}/teams`,
    team
  );
  return teamSchema.parse(data);
}

export async function updateTeam({
  tenant,
  teamId,
  team,
}: {
  tenant: number;
  teamId: string;
  team: TeamUpsertServiceModel;
}) {
  const { data } = await axios.put<TeamServiceModel>(
    `${BONGO_URL}/v1/org/${tenant}/teams/${teamId}`,
    team
  );
  return teamSchema.parse(data);
}

export async function deleteTeam(tenant: number, teamId: string) {
  return axios.delete(`${BONGO_URL}/v1/org/${tenant}/teams/${teamId}`);
}

export const useGetTeams = (tenant: number) =>
  useQuery(['teams', tenant], () => getTeams(tenant));

export const useCreateTeam = ({
  tenant,
  onSuccess,
  onError,
}: {
  tenant: number;
  onSuccess?: (team: TeamServiceModel) => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (team: TeamUpsertServiceModel) => createTeam({ tenant, team }),
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['teams', tenant]);
        onSuccess?.(data);
      },
      onError,
    }
  );
};

export const useUpdateTeam = ({
  tenant,
  onSuccess,
  onError,
}: {
  tenant: number;
  onSuccess?: (team: TeamServiceModel) => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ teamId, team }: { teamId: string; team: TeamUpsertServiceModel }) =>
      updateTeam({ tenant, teamId, team }),
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['teams', tenant]);
        onSuccess?.(data);
      },
      onError,
    }
  );
};

export const useDeleteTeam = ({
  tenant,
  onSuccess,
  onError,
}: {
  tenant: number;
  onSuccess?: (team: TeamServiceModel) => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation((team: TeamServiceModel) => deleteTeam(tenant, team._id), {
    onSuccess: async (data, team) => {
      await queryClient.invalidateQueries(['teams', tenant]);
      onSuccess?.(team);
    },
    onError,
  });
};

export const useUpdateUserTeam = ({
  tenant,
  onSuccess,
  onError,
}: {
  tenant: number;
  onSuccess?: (teamId: string) => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, teamId }: { userId: string; teamId: string }) =>
      updateUserTeam({ tenant, userId, teamId }),
    {
      onSuccess: async (data, { teamId }) => {
        await queryClient.invalidateQueries(['users', tenant]);
        onSuccess?.(teamId);
      },
      onError,
    }
  );
};

export async function updateUserRole(
  tenant: number,
  newRole: UserRoles,
  userToUpdate: UserServiceModel
): Promise<UserRoles> {
  const result = await Swal.fire({
    title: `Edit ${userToUpdate.email}'s user role?`,
    html: `This will switch the current user role "${userToUpdate.role}" to "${newRole}"`,
    confirmButtonText: 'Confirm',
    showDenyButton: true,
    denyButtonText: 'Cancel',
    denyButtonColor: '#6e7881',
    reverseButtons: true,
  });
  if (result.isConfirmed) {
    Swal.fire({
      title: 'Request in progress',
      icon: 'info',
      allowOutsideClick: () => !Swal.isLoading(),
    });
    Swal.showLoading();
    try {
      await axios.put(
        `${BONGO_URL}/v1/org/${tenant}/users/${userToUpdate._id}/role`,
        {
          role: newRole,
        }
      );
      await Swal.fire({
        icon: 'success',
        title: 'User role updated successfully!',
      });
      return newRole;
    } catch (err) {
      await Swal.fire({
        icon: 'error',
        title: `Request failed: ${err?.message}!`,
      });
      return userToUpdate.role as UserRoles;
    }
  }
}

export async function inviteNewUser(
  tenant: number,
  teams: TeamServiceModel[]
): Promise<UserServiceModel> {
  const reactSwal = withReactContent(Swal);
  const ReactSwalContent: JSX.Element = (
    <div className="text-left">
      <form>
        <FormGroup controlId="new_user_email">
          <ControlLabel>Email</ControlLabel>
          <FormControl type="text" placeholder="Email" id="new_user_email" />
        </FormGroup>
        <FormGroup controlId="new_user_team">
          <ControlLabel>Team</ControlLabel>
          <FormControl componentClass="select" id="new_user_team">
            {teams.map((team) => (
              <option value={team._id} key={team._id}>
                {team.name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <FormGroup controlId="new_user_role">
          <ControlLabel>Role</ControlLabel>
          <FormControl componentClass="select" id="new_user_role">
            {['salesUser', 'user', 'admin', 'architect'].map((role) => (
              <option value={role} key={role}>
                {upperFirst(lowerCase(role))}
              </option>
            ))}
          </FormControl>
        </FormGroup>
      </form>
      <div>
        <p>
          <b>Admins</b> can access all areas, add/remove users, change
          configurations (models, mappings…), change the Sales Intelligence
          layout
          <ul>
            <li className="font-italic text-muted">
              Recommended for the Account admin
            </li>
          </ul>
        </p>
        <p>
          <b>Architects</b> can access all areas except the user management
          section, change configurations (models, mappings…), change the Sales
          Intelligence layout
          <ul>
            <li className="font-italic text-muted">
              Recommended for MOps, SalesOps
            </li>
          </ul>
        </p>
        <p>
          <b>Users</b> can access in view-only all configurations (models,
          mappings...). Users don’t have access to the Sales Intelligence
          <ul>
            <li className="font-italic text-muted">
              Recommended for Marketing, Data, Growth, Product who only need to
              consult
            </li>
          </ul>
        </p>
        <p>
          <b>Sales users</b> only get access to the Sales Intelligence module
          (when installed in Salesforce) in view-only
          <ul>
            <li className="font-italic text-muted">
              Recommended for SDR, BDR, AE, AM...
            </li>
          </ul>
        </p>
        <p>
          <a
            href="https://support.madkudu.com/hc/en-us/articles/4403640927629-User-roles-and-permissions"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about Roles and Permissions
          </a>
        </p>
      </div>
    </div>
  );
  const result = await reactSwal.fire<{
    newUserEmail: string;
    role: UserRoles;
    team: string;
  }>({
    title: 'Add new user',
    html: ReactSwalContent as any,
    confirmButtonText: 'Invite user',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-primary mr-2',
    },
    reverseButtons: true,
    showCancelButton: true,
    preConfirm: () => {
      const newUserEmail = (document.querySelector(
        '#new_user_email'
      ) as HTMLInputElement).value;
      const role = (document.querySelector(
        '#new_user_role'
      ) as HTMLSelectElement).value as UserRoles;
      const team = (document.querySelector(
        '#new_user_team'
      ) as HTMLSelectElement).value;
      return { newUserEmail, role, team };
    },
  });
  const { isConfirmed, value } = result;
  if (isConfirmed) {
    const { newUserEmail, role, team } = value;
    Swal.fire({
      title: 'Request in progress',
      icon: 'info',
      allowOutsideClick: () => !Swal.isLoading(),
    });
    Swal.showLoading();
    try {
      const { data } = await axios.post<{ _id: string; email: string }>(
        `${BONGO_URL}/v1/org/${tenant}/users`,
        {
          role,
          team,
          email: newUserEmail,
        }
      );
      await Swal.fire({
        icon: 'success',
        title: `Invitation sent successfully to ${newUserEmail}!`,
      });
      return {
        _id: data._id,
        email: data.email,
        role,
        team,
        last_name: '',
        first_name: '',
        permissions: [],
      };
    } catch (err) {
      await Swal.fire({
        icon: 'error',
        title: `Something went wrong! Please reach out to our support team.`,
      });
    }
  }
}

export async function deleteUser(
  user: UserServiceModel,
  tenant: number
): Promise<boolean> {
  const { email, _id } = user;
  const result = await Swal.fire({
    title: `Delete ${email}?`,
    html: `This will delete ${email} permanently. Proceed?`,
    confirmButtonText: `Yes, delete ${email}`,
    showDenyButton: true,
    denyButtonText: 'Cancel',
    denyButtonColor: '#6e7881',
    reverseButtons: true,
  });
  if (result.isConfirmed) {
    Swal.fire({
      title: 'Deletion in progress',
      icon: 'info',
      allowOutsideClick: () => !Swal.isLoading(),
    });
    Swal.showLoading();
    try {
      await axios.delete(`${BONGO_URL}/v1/org/${tenant}/users/${_id}`);
      await Swal.fire({
        icon: 'success',
        title: `${email} has been deleted!`,
      });
      return true;
    } catch (err) {
      await Swal.fire({
        icon: 'error',
        title: `Something went wrong, we weren't able to delete the user! Please try again or reach out to our support team.`,
      });
    }
  }
  return false;
}
