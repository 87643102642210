import React from 'react';
import CsvDropzone from './CsvDropzone';

interface CsvUploadProps {
  tenant: number;
  email: string;
}

export default function CsvUpload({ email, tenant }: CsvUploadProps) {
  return (
    <div className="row justify-content-center">
      <div className="card box-shadow-3d h-100 w-75 mt-3">
        <div className="card-header">
          Request user data deletion under Data Privacy regulations
        </div>
        <div className="card-body" style={{ height: '500px' }}>
          <p>
            Records associated to these emails will be permanently deleted. The
            data will be unretrievable.
            <br />
            <br />
            You will receive a confirmation email when your request is
            processed.
            <br />
            <br />
            Please provide a valid <b>.csv</b> file with an <b>email header</b>{' '}
            and <b>valid emails.</b>
            <br />
            <br />
            <a
              href="https://support.madkudu.com/hc/en-us/articles/360036905991"
              target="_blank"
              rel="noopener noreferrer"
            >
              Here's a quick guide.
            </a>
          </p>
          <CsvDropzone tenant={tenant} email={email} />
        </div>
      </div>
    </div>
  );
}
