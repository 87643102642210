import axios from 'axios';
import Backbone from 'backbone';
import Mn from 'backbone.marionette';
import $ from 'jquery';

const sidebar_template = require('./sidebar.pug');
const subnav_template = require('./sidebar_subnav.pug');

const SubnavView = Mn.View.extend({
  template(data: {}) {
    return subnav_template(data);
  },

  templateContext() {
    return {
      flags: this.getOption('flags'),
    };
  },

  show() {
    $('.subnav').removeClass('hidden');
    $('.main-wrapper').addClass('subnav-visible');
  },

  hide() {
    $('.main-wrapper').removeClass('subnav-visible');
    $('.subnav').addClass('hidden');
  },

  highlight(subpage: string) {
    const subnav_nav_class = `subnav--${subpage}`;
    $(`.${subnav_nav_class}`).addClass('active');
  },
});

export default Mn.View.extend({
  regions: {
    subnav: '#subnav',
  },

  template(data: {}) {
    return sidebar_template(data);
  },

  // this function is from Marionette and is used to pass the flags attribute from the view to the template
  templateContext() {
    return {
      flags: this.getOption('flags'),
    };
  },

  events: {
    'click .sidebar a': 'open_link',
    'click .logout': 'logout',
    'click .subnav a': 'open_link',
    'click #madkudu_image': 'go_home',
  },

  initialize(options: { sidebar?: any; onboarding?: any; flags?: any } = {}) {
    const { sidebar, onboarding } = options;
    this.options.onboarding =
      typeof onboarding !== 'undefined' ? onboarding : false;
    this.options.sidebar =
      typeof sidebar !== 'undefined' ? sidebar : !this.options.onboarding; // show the sidebar, except in onboarding mode
    this.subnav = new SubnavView({ model: this.model, flags: options.flags });
  },

  go_home() {
    // sends the user to the initial page
    Backbone.history.navigate('/', true);
  },

  onRender() {
    this.showChildView('subnav', this.subnav);
    if (this.options.sidebar) {
      this.show();
    }
  },

  show() {
    $('.sidebar', this.$el).removeClass('hidden');
    $('.main-wrapper').addClass('sidebar-visible');
  },

  open_link(e: any) {
    e.preventDefault();

    const target = $(e.currentTarget).attr('href');
    if (!target) return;

    if (target.substring(0, 4) === 'http') {
      window.open(target, '_blank'); // if external link (documentation), open in a new window
    } else {
      Backbone.history.navigate(target, { trigger: true });
    }
    this.toggle_subnav(target);
  },

  toggle_subnav(target: string) {
    if (/settings/.test(target)) {
      this.subnav.show();
    } else {
      this.subnav.hide();
    }
  },

  highlight(page: string, subpage: string) {
    $('.sidebar__nav li:not(.sidebar__nav--changelog)').on(
      'click',
      function () {
        $('.sidebar__nav li.active').removeClass('active');
        $(this).addClass('active');
      }
    );

    $('.sidebar__logo').on('click', function () {
      $('.sidebar__nav li.active').removeClass('active');
      $('li.sidebar__nav--dashboard').addClass('active');
      $('li.sidebar__nav--get-started').addClass('active');
      $('li.sidebar__nav--homepage').addClass('active');
    });

    // $('.sidebar__nav > li').removeClass('active')
    let sidebar_nav_class = '';
    if (page.includes('integration')) {
      // fixes an issue with sidebar highlighting for integrations subpages
      sidebar_nav_class = 'sidebar__nav--integrations';
    } else {
      sidebar_nav_class = `sidebar__nav--${page}`;
    }
    $(`.${sidebar_nav_class}`, this.$el).addClass('active');
    if (subpage) {
      this.subnav.highlight(subpage);
    }
    this.toggle_subnav(page);
  },

  async logout(e: any) {
    e.preventDefault();
    e.stopPropagation();

    await axios.get(`${BONGO_URL}/v1/logout`);

    window.location.href = '/login';
  },
});
