import React from 'react';
import { Button } from 'react-bootstrap';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import AbstractMadMlMapping from '../AbstractMadMlMapping';
import MadMlMappingFactory from '../MadMlMappingFactory';
import MappingsMode from '../../enums/MappingsMode';
import { Loader } from '../../../components/Loader';
import MadMlAudienceMapping from '../MadMlAudienceMapping';
import { calculateNextPosition } from './utils';

require('codemirror/mode/sql/sql');

type MadMlEditModeComponentProps = {
  tenant: number;
  email: string;
  mappingMode: MappingsMode;
  goToDisplayMode: () => void;
  goBackToOverview: () => void;
  isReadOnly: boolean;
  scriptCheckerState: boolean;
  audienceName?: string;
  reloadScriptCheckerState?: () => void;
  isEventMapping?: boolean;
  isMadMlActive?: boolean;
  toggleActiveConnector?: () => void;
  setStatePostPublish?: () => void;
  madMlSqlQuery?: string;
  isCreatingAudienceMapping?: boolean;
  handleSqlQueryChange?: (q: string) => void;
};

type MadMlEditModeComponentState = {
  madMlSqlQuery: string;
  error: boolean;
  errorMessage: string;
  isPublishing: boolean;
  isCreation: boolean;
  codeMirrorCursorPosition: CodeMirror.Position;
};

export default class MadMlEditModeComponent extends React.Component<
  MadMlEditModeComponentProps,
  MadMlEditModeComponentState
> {
  madMlMapping: AbstractMadMlMapping;

  constructor(props: MadMlEditModeComponentProps) {
    super(props);
    this.state = {
      madMlSqlQuery: '',
      error: false,
      errorMessage: '',
      isPublishing: false,
      isCreation: false,
      codeMirrorCursorPosition: {
        ch: 0,
        line: 0,
      },
    };
    // Binds
    this.handleOnClickPublish = this.handleOnClickPublish.bind(this);
    this.handleOnClickCancel = this.handleOnClickCancel.bind(this);
    this.handleOnChangeSqlTextArea = this.handleOnChangeSqlTextArea.bind(this);
    this.handleOnCursorPositionChange = this.handleOnCursorPositionChange.bind(
      this
    );
  }

  componentDidUpdate(prevProps: Readonly<MadMlEditModeComponentProps>): void {
    if (
      this.props.isEventMapping &&
      this.props.madMlSqlQuery !== prevProps.madMlSqlQuery
    ) {
      this.setState({
        madMlSqlQuery: this.props.madMlSqlQuery,
      });
    }
  }

  async componentDidMount() {
    const {
      tenant,
      email,
      mappingMode,
      audienceName,
      madMlSqlQuery: madMlSqlQueryProps,
      isCreatingAudienceMapping,
    } = this.props;

    const madMlMappingFactory = new MadMlMappingFactory();

    this.madMlMapping = await madMlMappingFactory.createMadMlMapping(
      tenant,
      email,
      mappingMode
    );

    if (mappingMode === MappingsMode.audience) {
      (this.madMlMapping as MadMlAudienceMapping).setAudienceName(audienceName);
    }

    let madMlSqlQuery: string;
    let isCreation: boolean;

    if (isCreatingAudienceMapping) {
      madMlSqlQuery = '';
      isCreation = true;
    } else {
      madMlSqlQuery =
        (await this.madMlMapping?.getMadMlData()) || madMlSqlQueryProps;
      isCreation = !(madMlSqlQuery?.length > 0);
    }

    this.setState({
      madMlSqlQuery,
      isCreation,
    });
  }

  async handleOnClickPublish() {
    const { madMlSqlQuery, isCreation } = this.state;

    this.setState({
      isPublishing: true,
    });

    try {
      await this.madMlMapping.launchPublishSwal(
        madMlSqlQuery,
        isCreation,
        this.props.mappingMode,
        this.props.reloadScriptCheckerState,
        this.props.setStatePostPublish,
        this.props.goToDisplayMode
      );

      this.setState({
        isPublishing: false,
      });
    } catch (e) {
      this.setState({
        error: true,
        errorMessage: e,
        isPublishing: false,
      });
    }
  }

  handleOnClickCancel() {
    this.props.goBackToOverview();
  }

  handleOnChangeSqlTextArea(madMlSqlQuery: string) {
    if (this.props.handleSqlQueryChange) {
      this.props.handleSqlQueryChange(madMlSqlQuery);
    }

    this.setState({
      madMlSqlQuery,
    });
  }

  handleOnCursorPositionChange(editorChange: CodeMirror.EditorChange) {
    const newPosition = calculateNextPosition(editorChange);
    this.setState({
      codeMirrorCursorPosition: newPosition,
    });
  }

  render() {
    const { madMlSqlQuery, isPublishing } = this.state;
    const {
      isMadMlActive,
      toggleActiveConnector,
      isEventMapping,
      isReadOnly,
      scriptCheckerState,
      mappingMode,
      audienceName,
    } = this.props;

    const isMadMlEmpty: boolean = madMlSqlQuery
      ? madMlSqlQuery.trim().length === 0
      : true;

    const isConversionMapping: boolean =
      mappingMode === MappingsMode.conversion;

    if (mappingMode === MappingsMode.audience) {
      (this.madMlMapping as MadMlAudienceMapping)?.setAudienceName(
        audienceName
      );
    }

    return (
      <div>
        {isEventMapping && (
          <div className="alert alert-danger box-shadow-3d">
            <h4 className="alert-heading"> Warning </h4>
            <p>
              Publishing this as "live" will override all other previous event
              mapping configurations.
            </p>
          </div>
        )}
        <div
          className={`card box-shadow-3d ${
            !isConversionMapping ? 'mb-4 mt-4' : ''
          }`}
          style={{ height: '500px' }}
        >
          <div className="card-header">
            <div className="row">
              <div className="col-auto my-auto mr-1">
                <h4 className="mb-0">MadML Custom Query Input</h4>
              </div>
              {isEventMapping && (
                <div className="col-auto my-auto">
                  <div className="col-auto my-auto">
                    {isPublishing ? (
                      <div className="ml-2">
                        <Loader />
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            disabled={isMadMlEmpty}
                            onClick={() => toggleActiveConnector()}
                            checked={isMadMlActive}
                          ></input>
                          <label
                            className="custom-control-label my-auto"
                            htmlFor="customSwitch1"
                          >
                            {isMadMlActive ? 'On' : 'Off'}
                          </label>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card-body h-100">
            <p>
              <strong>How-to: </strong>
              Input your SQL queries and publish them as part of data pipeline.
            </p>
            <p>
              <a
                href="https://support.madkudu.com/hc/en-us/articles/360052356352"
                target="_blank"
                rel="noopener noreferrer"
              >
                Here's a quick guide.{' '}
              </a>
              Be sure to check your queries before submitting. Once submitted,
              you will receive a success email within a hour.
            </p>
            <CodeMirror
              value={madMlSqlQuery}
              className="w-100 h-75"
              cursor={this.state.codeMirrorCursorPosition}
              options={{
                mode: 'sql',
                lineNumbers: true,
              }}
              onChange={(_editor, data, value) => {
                this.handleOnCursorPositionChange(data);
                if (!isPublishing && !isReadOnly && !scriptCheckerState) {
                  this.handleOnChangeSqlTextArea(value);
                }
              }}
            />
          </div>
        </div>
        {!isConversionMapping && (
          <div className="row mt-3">
            <div className="col-sm-12 text-center">
              {!isEventMapping && (
                <Button
                  bsStyle="danger"
                  onClick={this.handleOnClickCancel}
                  disabled={isPublishing}
                >
                  Cancel
                </Button>
              )}
              {!isReadOnly && (
                <Button
                  bsStyle="primary"
                  className="ml-2"
                  disabled={isPublishing || scriptCheckerState || isMadMlEmpty}
                  onClick={this.handleOnClickPublish}
                >
                  {isPublishing ? 'Publishing...' : 'Publish'}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
