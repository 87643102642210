import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useApolloIsConnected } from './hooks/useApolloIsConnected';

type Props = {
  tenant: number;
  changeActiveTab: (tab: string) => void;
  hasAccessToConfiguration: boolean;
};

function ApolloOverview({ tenant, changeActiveTab }: Props) {
  const { data: apiKey, isLoading } = useApolloIsConnected(tenant);

  return (
    <div className="d-flex">
      <div className="row w-75 mr-4">
        <div className="col">
          <div className="row justify-space-between">
            <div className="col-8">
              <h5>Overview</h5>
              <div>
                MadKudu integrates with{' '}
                <a
                  href="http://apollo.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apollo.io
                </a>{' '}
                to enhance MadKudu Copilot as a comprehensive solution for
                targeted sales prospecting. Sellers no longer need to search
                outside MadKudu to find and engage the right buyers. Through
                MadKudu Copilot, users seamlessly access{' '}
                <a
                  href="http://apollo.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apollo.io
                </a>
                ’s vast database to identify essential buyer contacts not yet in
                their systems. This integration allows sellers to initiate
                tailored sequences and conduct effective outreach directly
                within MadKudu.
              </div>
            </div>
          </div>

          <div className="mt-3">
            <h5>How MadKudu x Apollo.io works</h5>
            <div>
              <h6>Pre-requisite</h6>
              <ul className="pl-3">
                <li>
                  You must have an{' '}
                  <a
                    href="http://apollo.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apollo.io
                  </a>{' '}
                  account. Not a customer yet?{' '}
                  <a
                    href="https://www.apollo.io/sign-up"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Start with their free plan!
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5>Once Apollo.io connected, your users will be able to</h5>
              <ul className="pl-3">
                <li>Discover key missing contacts in their book of accounts</li>
                <li>Add these new contacts into Salesforce</li>
                <li>
                  Add these new contacts to Gong flows, Outreach sequences,
                  Salesloft cadences
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-25">
        <p>
          <b>Status</b>
          <span className="d-block pt-1">
            {isLoading ? (
              <span className="text-secondary">loading...</span>
            ) : (
              <span
                className="cursor-pointer d-block"
                onClick={() => changeActiveTab('configuration')}
              >
                {apiKey ? (
                  <b className="tw-text-green-900 d-block p-2 rounded tw-bg-[#c5f3da]">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Connected
                  </b>
                ) : (
                  <b className="tw-text-gray-500 d-block p-2 rounded tw-bg-gray-100">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Not Connected
                  </b>
                )}
              </span>
            )}
          </span>
        </p>
        <p className="mt-4">
          <b>Links</b>
        </p>

        <p>
          <a
            href="https://apollo.partnerlinks.io/98d3tcy7ku8w"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up for Apollo.io
          </a>
        </p>

        <p>
          <a
            href="https://knowledge.apollo.io/hc/en-us/articles/4415734629773-Configure-Access-to-the-Apollo-REST-API"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get an Apollo.io API key
          </a>
        </p>

        <p>
          <a
            href="https://support.madkudu.com/hc/en-us/articles/25930634345869-Apollo-io"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to use MadKudu x Apollo.io
          </a>
        </p>

        <p>
          <a
            href="https://knowledge.apollo.io/hc/en-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apollo.io documentation
          </a>
        </p>
      </div>
    </div>
  );
}

export { ApolloOverview };
