var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isLikelihood, isMQA, tenant) {
      pug_html = pug_html + "\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav navbar-brand mr-auto mt-2 mt-lg-0\"\u003E\u003Cli class=\"breadcrumb-item active\" aria-current=\"page\"\u003EPredictive Models\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Cdiv class=\"my-2 my-lg-0 form-inline\"\u003E\u003Ca class=\"mb-0 mr-2\" href=\"https:\u002F\u002Fsupport.madkudu.com\u002Fhc\u002Fen-us\u002Farticles\u002F4404181748621-Introduction-to-MadKudu-Data-Science-Studio\" target=\"_blank\"\u003E\u003Cbutton class=\"btn btn-primary\"\u003EDocumentation\u003C\u002Fbutton\u003E\u003C\u002Fa\u003E\u003Ca" + (" class=\"mb-0 mr-2\""+pug.attr("href", `https://studio.madkudu.com/tenant/${tenant}`, true, true)+" target=\"_blank\"") + "\u003E\u003Cbutton class=\"btn btn-primary mb-0 mr-2\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"Build or edit models, view samples of records, create signals for your Sales team and much more!\"\u003EData Studio\u003C\u002Fbutton\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fnav\u003E\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav mx-auto mt-2 mt-lg-0\"\u003E\u003Cdiv class=\"p\"\u003EThis is where you can view your live MadKudu models and get in-depth reports on how they are performing.\u003C\u002Fdiv\u003E\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003Cdiv\u003E\u003Cdiv class=\"container mt-4 pb-5\"\u003E\u003Cdiv class=\"row justify-content-center\"\u003E\u003Cdiv class=\"col-md-8\"\u003E\u003C\u002Fdiv\u003E";
if (!(isLikelihood && isMQA)) {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-primary\" hidden id=\"alert\" role=\"alert\" style=\"background: none; padding: 15px; box-shadow: none; background-color: #CDE5FF\"\u003EIt looks like you have a few locked models. Upgrade your plan to add more insights to your funnel!\u003Ca class=\"ml-3\" href=\"https:\u002F\u002Fcalendly.com\u002Fhayden-madkudu\u002F30min\" target=\"_blank\"\u003E\u003Cbutton class=\"btn btn-primary text-white\"\u003EUnlock\u003C\u002Fbutton\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"row justify-content-center\"\u003E\u003Cdiv class=\"col-md-8\"\u003E\u003Cdiv class=\"list__models\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (!(isLikelihood)) {
pug_html = pug_html + "\u003Cdiv class=\"col-md-8\" hidden id=\"likelihood\"\u003E\u003Cdiv class=\"card box-shadow-3d card mt-md-2 d-flex dimmed rounded\"\u003E\u003Cimg class=\"locked-content h-25\" src=\"\u002Fmedia\u002Ficons\u002Flock.svg\"\u003E\u003Ch5 class=\"card-header\"\u003ELikelihood to Buy\u003Cdiv class=\"float-right\"\u003E\u003Cdiv class=\"badge badge-red\"\u003EINACTIVE\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fh5\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cp\u003EBy using your in-app data, we’ll tell you exactly when your leads are engaged with your product so that you can talk to them when it’s the most relevant time.\u003C\u002Fp\u003E\u003Crow class=\"mb-3\"\u003E\u003Cspan\u003E\u003Cb\u003EScores Included:\u003C\u002Fb\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-primary p-2 ml-1 mb-2 text-white\"\u003EVery High\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-teal p-2 ml-1 mb-2 text-white\"\u003EHigh\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-yellow p-2 ml-1 mb-2 text-white\"\u003EMedium\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-red p-2 ml-1 mb-2 text-white\"\u003ELow\u003C\u002Fspan\u003E\u003C\u002Frow\u003E\u003Cp\u003E\u003Cb\u003EData Sources Included:\u003C\u002Fb\u003E\u003C\u002Fp\u003E\u003Cp\u003EWebsite activity\u003C\u002Fp\u003E\u003Cp\u003EProduct usage\u003C\u002Fp\u003E\u003Cp\u003EEmail activity\u003C\u002Fp\u003E\u003Cp\u003EMarketing campaigns\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (!(isMQA)) {
pug_html = pug_html + "\u003Cdiv class=\"col-md-8\" hidden id=\"MQA\"\u003E\u003Cdiv class=\"card box-shadow-3d card mt-md-2 d-flex dimmed rounded\"\u003E\u003Cimg class=\"locked-content h-25\" src=\"\u002Fmedia\u002Ficons\u002Flock.svg\"\u003E\u003Ch5 class=\"card-header\"\u003EAccount Likelihood to Buy\u003Cdiv class=\"float-right\"\u003E\u003Cdiv class=\"badge badge-red\"\u003EINACTIVE\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fh5\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cp\u003EBy using your in-app data, we’ll tell you exactly when your accounts are engaged with your product so that you can talk to them when it’s the most relevant time.\u003C\u002Fp\u003E\u003Crow class=\"mb-3\"\u003E\u003Cspan\u003E\u003Cb\u003EScores Included:\u003C\u002Fb\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-primary p-2 ml-1 mb-2 text-white\"\u003EVery High\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-teal p-2 ml-1 mb-2 text-white\"\u003EHigh\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-yellow p-2 ml-1 mb-2 text-white\"\u003EMedium\u003C\u002Fspan\u003E\u003Cspan class=\"badge badge-red p-2 ml-1 mb-2 text-white\"\u003ELow\u003C\u002Fspan\u003E\u003C\u002Frow\u003E\u003Cp\u003E\u003Cb\u003EData Sources Included:\u003C\u002Fb\u003E\u003C\u002Fp\u003E\u003Cp\u003EWebsite activity\u003C\u002Fp\u003E\u003Cp\u003EProduct usage\u003C\u002Fp\u003E\u003Cp\u003EEmail activity\u003C\u002Fp\u003E\u003Cp\u003EMarketing campaigns\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isLikelihood" in locals_for_with ?
        locals_for_with.isLikelihood :
        typeof isLikelihood !== 'undefined' ? isLikelihood : undefined, "isMQA" in locals_for_with ?
        locals_for_with.isMQA :
        typeof isMQA !== 'undefined' ? isMQA : undefined, "tenant" in locals_for_with ?
        locals_for_with.tenant :
        typeof tenant !== 'undefined' ? tenant : undefined));
    ;;return pug_html;};
module.exports = template;