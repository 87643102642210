import React from 'react';
import Swal from 'sweetalert2';
import { useMutation } from '@tanstack/react-query';
import { useSnowflakeContext } from '../contexts/SnowflakeContext';

type Props = {
  nextStep: () => void;
};

export function CredentialsStep({ nextStep }: Props) {
  const {
    credentials,
    setCredentials,
    testCredentials,
  } = useSnowflakeContext();

  const { mutate: test, isLoading: isTesting } = useMutation(testCredentials);

  const handleNextClick = async () => {
    if (
      !credentials?.account ||
      !credentials?.username ||
      !credentials?.pwd ||
      !credentials?.database ||
      !credentials?.schema
    ) {
      Swal.fire({
        title: 'Invalid credentials',
        text: 'Please fill all the required fields',
        icon: 'error',
      });

      return;
    }

    test(null, {
      onSettled(state, error) {
        if (error || !state) {
          Swal.fire({
            title: 'Invalid credentials',
            text:
              'MadKudu is unable to connect with these credentials. Please check credentials or permissions',
            icon: 'error',
          });
        } else {
          nextStep();
        }
      },
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="card box-shadow-3d mb-3">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <img
                  className="con-map-mk-integration-logo-size"
                  style={{ width: '30px' }}
                  src="/media/integrations/logos/snowflake-icon.png"
                />
                <h5 className="mb-0 ml-3">Snowflake</h5>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <h5>Overview</h5>
                  <p className="mt-3">
                    To give MadKudu access to your Snowflake Database, you will
                    be invited go through the configuration process.
                  </p>
                </div>
                <div className="col-md-4">
                  <b className="d-block">Links</b>
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/22333819128973-Snowflake"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    How to connect Snowflake to MadKudu
                  </a>
                </div>
              </div>

              <h5 className="mt-3">Snowflake credentials</h5>

              <form>
                <div className="form-group row">
                  <label htmlFor="account" className="col-sm-2 col-form-label">
                    Account <sup className="text-danger">*</sup>
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="account"
                      value={credentials?.account ?? ''}
                      required
                      onChange={(event) => {
                        setCredentials({
                          ...credentials,
                          account: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="username" className="col-sm-2 col-form-label">
                    Username <sup className="text-danger">*</sup>
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      value={credentials?.username ?? ''}
                      required
                      onChange={(event) => {
                        setCredentials({
                          ...credentials,
                          username: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="pwd" className="col-sm-2 col-form-label">
                    Password <sup className="text-danger">*</sup>
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="password"
                      className="form-control"
                      id="pwd"
                      value={credentials?.pwd ?? ''}
                      required
                      onChange={(event) => {
                        setCredentials({
                          ...credentials,
                          pwd: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="database" className="col-sm-2 col-form-label">
                    Database <sup className="text-danger">*</sup>
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="database"
                      value={credentials?.database ?? ''}
                      required
                      onChange={(event) => {
                        setCredentials({
                          ...credentials,
                          database: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="schema" className="col-sm-2 col-form-label">
                    Schema <sup className="text-danger">*</sup>
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="schema"
                      value={credentials?.schema ?? ''}
                      required
                      onChange={(event) => {
                        setCredentials({
                          ...credentials,
                          schema: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 pb-4 text-center">
        <button
          className="btn btn-primary"
          disabled={isTesting}
          onClick={handleNextClick}
        >
          {isTesting ? 'Testing credentials...' : 'Next'}
        </button>
      </div>
    </>
  );
}
