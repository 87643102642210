import React from 'react';

import {
  OVERVIEW_STEP,
  ADMIN_VIEW_STEP,
  EDIT_VIEW_STEP,
  FORCE_PUSH_STEP,
} from '../constants';

interface NavbarProps {
  isSuperKudu: boolean;
  userCanEdit: boolean;
  handleNavbarPageToggle: Function;
  contactUpdateObject: any;
}

export default function Navbar({
  contactUpdateObject,
  handleNavbarPageToggle,
  userCanEdit,
  isSuperKudu,
}: NavbarProps) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
      <ol className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0">
        <li className="breadcrumb-item" key="overview">
          <a
            className="breadcrumb-link text-primary"
            role="tab"
            onClick={() => handleNavbarPageToggle(OVERVIEW_STEP)}
          >
            Push Configuration
          </a>
        </li>
      </ol>

      {contactUpdateObject?.configuration?.lastUpdatedBy &&
        contactUpdateObject?.configuration?.lastUpdatedAt && (
          <div className="float-right my-auto mr-2 font-italic">
            {`Last configured on ${contactUpdateObject.configuration.lastUpdatedAt} by ${contactUpdateObject.configuration.lastUpdatedBy}`}
          </div>
        )}

      <div className="float-right my-2 my-lg-0">
        {userCanEdit && (
          <>
            <button
              onClick={() => handleNavbarPageToggle(ADMIN_VIEW_STEP)}
              className="btn btn-primary mr-2"
            >
              Admin
            </button>
            {contactUpdateObject?.configuration && (
              <button
                onClick={() => handleNavbarPageToggle(EDIT_VIEW_STEP)}
                className="btn btn-primary mr-2"
              >
                Edit configuration
              </button>
            )}
          </>
        )}
        {isSuperKudu && (
          <button
            onClick={() => handleNavbarPageToggle(FORCE_PUSH_STEP)}
            className="btn btn-warning"
          >
            Force push
          </button>
        )}
      </div>
    </nav>
  );
}
