import React from 'react';
import Backbone from 'backbone';
import { Button } from 'react-bootstrap';
import AttributeMappingManager from '../../models/AttributeMappingManager';
import AttributeFormComponent from '../../configurationSection/simple/AttributeFormComponent';
import { AttributeMappingData } from '../../models/AttributeMappingData';
import { ClassicConnectorsNames } from '../../../../types';
import DomainMappingFormComponent from '../../configurationSection/simple/DomainMappingFormComponent';
import { launchDeleteMappingPopup } from '../../../swal';
import MappingsMode from '../../../enums/MappingsMode';
import { redirectToInsightsReport } from '../../../utils';
import LeadMappingFormComponent from '../../configurationSection/simple/LeadMappingFormComponent';

type SimpleConfigurationParentComponentProps = {
  isReadOnly: boolean;
  isSuperKudu: boolean;
  lastPublisher: string;
  publishedAt: string;
  createdAt: string;
  shouldShowSalesforce: boolean;
  shouldShowHubspot: boolean;
  attributeMappingManager: AttributeMappingManager;
  tenant: number;
};

type SimpleConfigurationParentComponentState = {
  isEditClicked: boolean;
  attributeMappingData: AttributeMappingData;
  isScriptCheckerRunning: boolean;
};

export default class SimpleConfigurationParentComponent extends React.Component<
  SimpleConfigurationParentComponentProps,
  SimpleConfigurationParentComponentState
> {
  constructor(props: SimpleConfigurationParentComponentProps) {
    super(props);

    const { data, scriptCheckerStatus } = props.attributeMappingManager;
    this.state = {
      isEditClicked: false,
      attributeMappingData: data,
      isScriptCheckerRunning: !!scriptCheckerStatus,
    };

    this.handleOnClickEdit = this.handleOnClickEdit.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleFieldNameChange = this.handleFieldNameChange.bind(this);
    this.handleFieldNameDisplayChange = this.handleFieldNameDisplayChange.bind(
      this
    );
    this.handleRemoveAttribute = this.handleRemoveAttribute.bind(this);
    this.createNewAttribute = this.createNewAttribute.bind(this);
    this.handleDomainFieldsChange = this.handleDomainFieldsChange.bind(this);
    this.handleLeadFieldsChange = this.handleLeadFieldsChange.bind(this);
    this.showScriptCheckerRunningAlert = this.showScriptCheckerRunningAlert.bind(
      this
    );
    this.publish = this.publish.bind(this);
    this.copy = this.copy.bind(this);
    this.goBackToOverview = this.goBackToOverview.bind(this);
    this.remove = this.remove.bind(this);
    this.handleOnCheckVolumeClick = this.handleOnCheckVolumeClick.bind(this);
  }

  goBackToOverview() {
    const { attributeMappingManager } = this.props;

    Backbone.history.navigate(
      `org/${attributeMappingManager.tenant}/mapping/attribute_mapping/overview`,
      true
    );
  }

  handleOnClickEdit() {
    this.setState({
      isEditClicked: true,
    });
  }

  createNewAttribute(connector: ClassicConnectorsNames) {
    const newAttributeMappingData = this.props.attributeMappingManager.data.addForm(
      connector
    );

    this.setState({
      attributeMappingData: newAttributeMappingData,
    });
  }

  handleTableChange(
    connector: ClassicConnectorsNames,
    formIndex: number,
    newTable: string
  ) {
    const newAttributeMappingData = this.props.attributeMappingManager.data.setTable(
      connector,
      formIndex,
      newTable
    );

    this.setState({
      attributeMappingData: newAttributeMappingData,
    });
  }

  handleFieldNameChange(
    connector: ClassicConnectorsNames,
    formIndex: number,
    newField: string
  ) {
    const newAttributeMappingData = this.props.attributeMappingManager.data.setField(
      connector,
      formIndex,
      newField
    );

    this.setState({
      attributeMappingData: newAttributeMappingData,
    });
  }

  handleFieldNameDisplayChange(
    connector: ClassicConnectorsNames,
    formIndex: number,
    newFieldName: string
  ) {
    const newAttributeMappingData = this.props.attributeMappingManager.data.setFieldName(
      connector,
      formIndex,
      newFieldName
    );

    this.setState({
      attributeMappingData: newAttributeMappingData,
    });
  }

  handleRemoveAttribute(connector: ClassicConnectorsNames, formIndex: number) {
    const newAttributeMappingData = this.props.attributeMappingManager.data.removeForm(
      connector,
      formIndex
    );

    this.setState({
      attributeMappingData: newAttributeMappingData,
    });
  }

  handleDomainFieldsChange(domainFields: string[]) {
    const newAttributeMappingData = this.props.attributeMappingManager.data.setDomainFields(
      domainFields
    );

    this.setState({
      attributeMappingData: newAttributeMappingData,
    });
  }

  handleLeadFieldsChange(leadFields: string[]) {
    const newAttributeMappingData = this.props.attributeMappingManager.data.setLeadFields(
      leadFields
    );

    this.setState({
      attributeMappingData: newAttributeMappingData,
    });
  }

  showScriptCheckerRunningAlert(scriptCheckerStatus: boolean) {
    this.setState({
      isScriptCheckerRunning: scriptCheckerStatus,
    });
  }

  async publish() {
    const { attributeMappingManager } = this.props;
    const attributeMappingDataPostErrorCheck = attributeMappingManager.data.checkIfConfigurationIsValid();
    attributeMappingDataPostErrorCheck.isFixing = !!attributeMappingDataPostErrorCheck
      .errors?.length;
    await attributeMappingManager.publish((scriptCheckerStatus: boolean) =>
      this.showScriptCheckerRunningAlert(scriptCheckerStatus)
    );
    this.setState({
      attributeMappingData: attributeMappingDataPostErrorCheck,
    });
  }

  async copy() {
    const { attributeMappingManager } = this.props;
    attributeMappingManager.data = this.state.attributeMappingData;
    await attributeMappingManager.copy();
  }

  async remove() {
    const { attributeMappingManager } = this.props;
    const { tenant, email } = attributeMappingManager;
    await launchDeleteMappingPopup(tenant, email, MappingsMode.attribute, () =>
      this.goBackToOverview()
    );
  }

  handleOnCheckVolumeClick() {
    redirectToInsightsReport(
      MappingsMode.attribute,
      this.props.attributeMappingManager.tenant
    );
  }

  render() {
    const {
      isReadOnly,
      isSuperKudu,
      lastPublisher,
      publishedAt,
      createdAt,
      attributeMappingManager,
      tenant,
    } = this.props;
    const { data, isOld } = attributeMappingManager;
    const { isEditClicked, isScriptCheckerRunning } = this.state;

    return (
      <>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light box-shadow-3d"
          aria-label="breadcrumb"
        >
          <ul className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0">
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                href={`/org/${attributeMappingManager.tenant}/mapping`}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Attribute Mapping
            </li>
          </ul>
          <div className="my-2 my-lg-0">
            {!isReadOnly && (
              <div>
                <a href="https://support.madkudu.com/hc/en-us/articles/4403659825805-Attribute-mapping">
                  <button className="btn btn-primary mr-2">
                    Documentation
                  </button>
                </a>
                {!isEditClicked && (
                  <button
                    className="btn btn-primary mr-2"
                    id="edit-button"
                    onClick={this.handleOnClickEdit}
                  >
                    Edit
                  </button>
                )}
                <Button
                  bsStyle="primary"
                  className="mr-2"
                  onClick={this.handleOnCheckVolumeClick}
                >
                  Check volumes
                </Button>
                {isEditClicked && (
                  <button
                    className="btn btn-primary mr-2"
                    id="copy-button"
                    onClick={this.copy}
                  >
                    Copy
                  </button>
                )}
                {isEditClicked && (
                  <button
                    className="btn btn-primary"
                    id="publish-button"
                    onClick={this.publish}
                  >
                    Publish
                  </button>
                )}
              </div>
            )}
          </div>
        </nav>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d"
          aria-label="breadcrumb"
          id="nav_bar_info_attribute_mapping"
        >
          <ul className="navbar-nav mx-auto mt-2 mt-lg-0">
            <p>
              The Attribute mapping reconciles at the Person level enrichment
              across your different systems, to be used in Computations for Fit
              scoring or segmentation in the Data Studio.
            </p>
          </ul>
        </nav>
        <div className="container">
          <div className="ml-5 mt-2">
            {lastPublisher && (
              <p>
                {`Last Publisher: ${lastPublisher} last published at: ${publishedAt} | created at: ${createdAt}`}
              </p>
            )}
          </div>
          {isScriptCheckerRunning && (
            <div className="alert alert-warning box-shadow-3d alert-No-Data">
              <div className="spinner-border float-left mr-2" />
              <p>
                Your configuration has been submitted and is currently being
                processed. Please wait for an email to confirm success or
                failure of publish.
              </p>
            </div>
          )}
          <div className="ml-5 mt-2">
            {data.activeIntegrations.map((activeIntegration) => (
              <div
                className="card box-shadow-3d mb-2"
                key={`integration_card_${activeIntegration}`}
              >
                <div className="card-header">
                  <img
                    src={`/media/integrations/logos/${activeIntegration}.svg`}
                    height="45px"
                  />
                </div>
                <div className="card-body p-2">
                  {activeIntegration === 'salesforce' && (
                    <div className="p-2 col-md-12 col-lg-9">
                      <h3 className="mb-3">Email to Account mapping</h3>
                      <LeadMappingFormComponent
                        key="salesforce_lead_mapping_form"
                        attributeMappingManager={attributeMappingManager}
                        isEditClicked={isEditClicked}
                        onLeadFieldsChange={this.handleLeadFieldsChange}
                      />
                      <div className="mb-3" />
                      <DomainMappingFormComponent
                        key="salesforce_domain_mapping_form"
                        attributeMappingManager={attributeMappingManager}
                        isEditClicked={isEditClicked}
                        onDomainFieldsChange={this.handleDomainFieldsChange}
                      />
                    </div>
                  )}
                  <AttributeFormComponent
                    connector={activeIntegration}
                    tenant={tenant}
                    key={`${activeIntegration}_attribute_form`}
                    attributeMappingManager={attributeMappingManager}
                    isEditClicked={isEditClicked}
                    createNewAttribute={this.createNewAttribute}
                    handleTableChange={this.handleTableChange}
                    handleFieldNameChange={this.handleFieldNameChange}
                    handleFieldNameDisplayChange={
                      this.handleFieldNameDisplayChange
                    }
                    handleRemoveAttribute={this.handleRemoveAttribute}
                  />
                </div>
              </div>
            ))}
          </div>
          {isSuperKudu && isOld && (
            <div className="d-flex justify-content-center mt-4 mb-4">
              <button
                className="btn btn-danger"
                disabled={!isEditClicked}
                id="remove_button"
                onClick={this.remove}
              >
                Remove and create a new configuration
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
}
