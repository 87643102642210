const segment_key = SEGMENT_WRITE_KEY || '2DO3M69SRODhp7OB8a6Qry08ebf7GcG2'; // eslint-disable-line no-undef

const load_rollbar = function () {
  let _rollbarConfig = {
    accessToken: '080a9a5ae49e4f99a2cb937bcf9aedc1',
    rollbarJsUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/rollbar.js/2.18.0/rollbar.min.js', // update this to update rollbar
    // if you do update this url, don't forget to update the CSP in netlify.toml
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: '%NODE_ENV%' !== 'development',
    payload: {
      environment: process.env.NODE_ENV, // note: this might differ slightly in Rollbar from other applications (development/production instead of dev/prod)
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: COMMIT_REF, // eslint-disable-line no-undef
          guess_uncaught_frames: true,
        },
      },
    },
  };

  /* eslint-disable */
  // Rollbar Snippet
  !(function (r) {
    var e = {};

    function o(n) {
      if (e[n]) return e[n].exports;
      var t = (e[n] = {
        i: n,
        l: !1,
        exports: {},
      });
      return r[n].call(t.exports, t, t.exports, o), (t.l = !0), t.exports;
    }
    (o.m = r),
      (o.c = e),
      (o.d = function (r, e, n) {
        o.o(r, e) ||
          Object.defineProperty(r, e, {
            enumerable: !0,
            get: n,
          });
      }),
      (o.r = function (r) {
        'undefined' != typeof Symbol &&
          Symbol.toStringTag &&
          Object.defineProperty(r, Symbol.toStringTag, {
            value: 'Module',
          }),
          Object.defineProperty(r, '__esModule', {
            value: !0,
          });
      }),
      (o.t = function (r, e) {
        if ((1 & e && (r = o(r)), 8 & e)) return r;
        if (4 & e && 'object' == typeof r && r && r.__esModule) return r;
        var n = Object.create(null);
        if (
          (o.r(n),
          Object.defineProperty(n, 'default', {
            enumerable: !0,
            value: r,
          }),
          2 & e && 'string' != typeof r)
        )
          for (var t in r)
            o.d(
              n,
              t,
              function (e) {
                return r[e];
              }.bind(null, t)
            );
        return n;
      }),
      (o.n = function (r) {
        var e =
          r && r.__esModule
            ? function () {
                return r.default;
              }
            : function () {
                return r;
              };
        return o.d(e, 'a', e), e;
      }),
      (o.o = function (r, e) {
        return Object.prototype.hasOwnProperty.call(r, e);
      }),
      (o.p = ''),
      o((o.s = 0));
  })([
    function (r, e, o) {
      'use strict';
      var n = o(1),
        t = o(4);
      (_rollbarConfig = _rollbarConfig || {}),
        (_rollbarConfig.rollbarJsUrl =
          _rollbarConfig.rollbarJsUrl ||
          'https://cdn.rollbar.com/rollbarjs/refs/tags/v2.18.0/rollbar.min.js'),
        (_rollbarConfig.async =
          void 0 === _rollbarConfig.async || _rollbarConfig.async);
      var a = n.setupShim(window, _rollbarConfig),
        l = t(_rollbarConfig);
      (window.rollbar = n.Rollbar),
        a.loadFull(window, document, !_rollbarConfig.async, _rollbarConfig, l);
    },
    function (r, e, o) {
      'use strict';
      var n = o(2);

      function t(r) {
        return function () {
          try {
            return r.apply(this, arguments);
          } catch (r) {
            try {
              console.error('[Rollbar]: Internal error', r);
            } catch (r) {}
          }
        };
      }
      var a = 0;

      function l(r, e) {
        (this.options = r), (this._rollbarOldOnError = null);
        var o = a++;
        (this.shimId = function () {
          return o;
        }),
          'undefined' != typeof window &&
            window._rollbarShims &&
            (window._rollbarShims[o] = {
              handler: e,
              messages: [],
            });
      }
      var i = o(3),
        s = function (r, e) {
          return new l(r, e);
        },
        d = function (r) {
          return new i(s, r);
        };

      function c(r) {
        return t(function () {
          var e = this,
            o = Array.prototype.slice.call(arguments, 0),
            n = {
              shim: e,
              method: r,
              args: o,
              ts: new Date(),
            };
          window._rollbarShims[this.shimId()].messages.push(n);
        });
      }
      (l.prototype.loadFull = function (r, e, o, n, a) {
        var l = !1,
          i = e.createElement('script'),
          s = e.getElementsByTagName('script')[0],
          d = s.parentNode;
        (i.crossOrigin = ''),
          (i.src = n.rollbarJsUrl),
          o || (i.async = !0),
          (i.onload = i.onreadystatechange = t(function () {
            if (
              !(
                l ||
                (this.readyState &&
                  'loaded' !== this.readyState &&
                  'complete' !== this.readyState)
              )
            ) {
              i.onload = i.onreadystatechange = null;
              try {
                d.removeChild(i);
              } catch (r) {}
              (l = !0),
                (function () {
                  var e;
                  if (void 0 === r._rollbarDidLoad) {
                    e = new Error('rollbar.js did not load');
                    for (var o, n, t, l, i = 0; (o = r._rollbarShims[i++]); )
                      for (o = o.messages || []; (n = o.shift()); )
                        for (t = n.args || [], i = 0; i < t.length; ++i)
                          if ('function' == typeof (l = t[i])) {
                            l(e);
                            break;
                          }
                  }
                  'function' == typeof a && a(e);
                })();
            }
          })),
          d.insertBefore(i, s);
      }),
        (l.prototype.wrap = function (r, e, o) {
          try {
            var n;
            if (
              ((n =
                'function' == typeof e
                  ? e
                  : function () {
                      return e || {};
                    }),
              'function' != typeof r)
            )
              return r;
            if (r._isWrap) return r;
            if (
              !r._rollbar_wrapped &&
              ((r._rollbar_wrapped = function () {
                o && 'function' == typeof o && o.apply(this, arguments);
                try {
                  return r.apply(this, arguments);
                } catch (o) {
                  var e = o;
                  throw (
                    (e &&
                      ('string' == typeof e && (e = new String(e)),
                      (e._rollbarContext = n() || {}),
                      (e._rollbarContext._wrappedSource = r.toString()),
                      (window._rollbarWrappedError = e)),
                    e)
                  );
                }
              }),
              (r._rollbar_wrapped._isWrap = !0),
              r.hasOwnProperty)
            )
              for (var t in r)
                r.hasOwnProperty(t) && (r._rollbar_wrapped[t] = r[t]);
            return r._rollbar_wrapped;
          } catch (e) {
            return r;
          }
        });
      for (
        var u = 'log,debug,info,warn,warning,error,critical,global,configure,handleUncaughtException,handleAnonymousErrors,handleUnhandledRejection,captureEvent,captureDomContentLoaded,captureLoad'.split(
            ','
          ),
          p = 0;
        p < u.length;
        ++p
      )
        l.prototype[u[p]] = c(u[p]);
      r.exports = {
        setupShim: function (r, e) {
          if (r) {
            var o = e.globalAlias || 'Rollbar';
            if ('object' == typeof r[o]) return r[o];
            (r._rollbarShims = {}), (r._rollbarWrappedError = null);
            var a = new d(e);
            return t(function () {
              e.captureUncaught &&
                ((a._rollbarOldOnError = r.onerror),
                n.captureUncaughtExceptions(r, a, !0),
                e.wrapGlobalEventHandlers && n.wrapGlobals(r, a, !0)),
                e.captureUnhandledRejections &&
                  n.captureUnhandledRejections(r, a, !0);
              var t = e.autoInstrument;
              return (
                !1 !== e.enabled &&
                  (void 0 === t ||
                    !0 === t ||
                    ('object' == typeof t && t.network)) &&
                  r.addEventListener &&
                  (r.addEventListener('load', a.captureLoad.bind(a)),
                  r.addEventListener(
                    'DOMContentLoaded',
                    a.captureDomContentLoaded.bind(a)
                  )),
                (r[o] = a),
                a
              );
            })();
          }
        },
        Rollbar: d,
      };
    },
    function (r, e, o) {
      'use strict';

      function n(r, e, o, n) {
        r._rollbarWrappedError &&
          (n[4] || (n[4] = r._rollbarWrappedError),
          n[5] || (n[5] = r._rollbarWrappedError._rollbarContext),
          (r._rollbarWrappedError = null));
        var t = e.handleUncaughtException.apply(e, n);
        o && o.apply(r, n),
          'anonymous' === t && (e.anonymousErrorsPending += 1);
      }

      function t(r, e, o) {
        if (e.hasOwnProperty && e.hasOwnProperty('addEventListener')) {
          for (
            var n = e.addEventListener;
            n._rollbarOldAdd && n.belongsToShim;

          )
            n = n._rollbarOldAdd;
          var t = function (e, o, t) {
            n.call(this, e, r.wrap(o), t);
          };
          (t._rollbarOldAdd = n),
            (t.belongsToShim = o),
            (e.addEventListener = t);
          for (
            var a = e.removeEventListener;
            a._rollbarOldRemove && a.belongsToShim;

          )
            a = a._rollbarOldRemove;
          var l = function (r, e, o) {
            a.call(this, r, (e && e._rollbar_wrapped) || e, o);
          };
          (l._rollbarOldRemove = a),
            (l.belongsToShim = o),
            (e.removeEventListener = l);
        }
      }
      r.exports = {
        captureUncaughtExceptions: function (r, e, o) {
          if (r) {
            var t;
            if ('function' == typeof e._rollbarOldOnError)
              t = e._rollbarOldOnError;
            else if (r.onerror) {
              for (t = r.onerror; t._rollbarOldOnError; )
                t = t._rollbarOldOnError;
              e._rollbarOldOnError = t;
            }
            e.handleAnonymousErrors();
            var a = function () {
              var o = Array.prototype.slice.call(arguments, 0);
              n(r, e, t, o);
            };
            o && (a._rollbarOldOnError = t), (r.onerror = a);
          }
        },
        captureUnhandledRejections: function (r, e, o) {
          if (r) {
            'function' == typeof r._rollbarURH &&
              r._rollbarURH.belongsToShim &&
              r.removeEventListener('unhandledrejection', r._rollbarURH);
            var n = function (r) {
              var o, n, t;
              try {
                o = r.reason;
              } catch (r) {
                o = void 0;
              }
              try {
                n = r.promise;
              } catch (r) {
                n = '[unhandledrejection] error getting `promise` from event';
              }
              try {
                (t = r.detail), !o && t && ((o = t.reason), (n = t.promise));
              } catch (r) {}
              o ||
                (o = '[unhandledrejection] error getting `reason` from event'),
                e &&
                  e.handleUnhandledRejection &&
                  e.handleUnhandledRejection(o, n);
            };
            (n.belongsToShim = o),
              (r._rollbarURH = n),
              r.addEventListener('unhandledrejection', n);
          }
        },
        wrapGlobals: function (r, e, o) {
          if (r) {
            var n,
              a,
              l = 'EventTarget,Window,Node,ApplicationCache,AudioTrackList,ChannelMergerNode,CryptoOperation,EventSource,FileReader,HTMLUnknownElement,IDBDatabase,IDBRequest,IDBTransaction,KeyOperation,MediaController,MessagePort,ModalWindow,Notification,SVGElementInstance,Screen,TextTrack,TextTrackCue,TextTrackList,WebSocket,WebSocketWorker,Worker,XMLHttpRequest,XMLHttpRequestEventTarget,XMLHttpRequestUpload'.split(
                ','
              );
            for (n = 0; n < l.length; ++n)
              r[(a = l[n])] && r[a].prototype && t(e, r[a].prototype, o);
          }
        },
      };
    },
    function (r, e, o) {
      'use strict';

      function n(r, e) {
        (this.impl = r(e, this)),
          (this.options = e),
          (function (r) {
            for (
              var e = function (r) {
                  return function () {
                    var e = Array.prototype.slice.call(arguments, 0);
                    if (this.impl[r]) return this.impl[r].apply(this.impl, e);
                  };
                },
                o = 'log,debug,info,warn,warning,error,critical,global,configure,handleUncaughtException,handleAnonymousErrors,handleUnhandledRejection,_createItem,wrap,loadFull,shimId,captureEvent,captureDomContentLoaded,captureLoad'.split(
                  ','
                ),
                n = 0;
              n < o.length;
              n++
            )
              r[o[n]] = e(o[n]);
          })(n.prototype);
      }
      (n.prototype._swapAndProcessMessages = function (r, e) {
        var o, n, t;
        for (this.impl = r(this.options); (o = e.shift()); )
          (n = o.method),
            (t = o.args),
            this[n] &&
              'function' == typeof this[n] &&
              ('captureDomContentLoaded' === n || 'captureLoad' === n
                ? this[n].apply(this, [t[0], o.ts])
                : this[n].apply(this, t));
        return this;
      }),
        (r.exports = n);
    },
    function (r, e, o) {
      'use strict';
      r.exports = function (r) {
        return function (e) {
          if (!e && !window._rollbarInitialized) {
            for (
              var o,
                n,
                t = (r = r || {}).globalAlias || 'Rollbar',
                a = window.rollbar,
                l = function (r) {
                  return new a(r);
                },
                i = 0;
              (o = window._rollbarShims[i++]);

            )
              n || (n = o.handler),
                o.handler._swapAndProcessMessages(l, o.messages);
            (window[t] = n), (window._rollbarInitialized = !0);
          }
        };
      };
    },
  ]);
  // End Rollbar Snippet
  /* eslint-enable */
};

const load_segment = function () {
  /* eslint-disable */
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'page',
          'once',
          'off',
          'on',
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t) {
          var e = document.createElement('script');
          e.type = 'text/javascript';
          e.async = !0;
          e.src =
            ('https:' === document.location.protocol ? 'https://' : 'http://') +
            'cdn.segment.com/analytics.js/v1/' +
            t +
            '/analytics.min.js';
          var n = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(e, n);
        };
        analytics.SNIPPET_VERSION = '3.1.0';
        if (!__MADKUDU_JS__) {
          analytics.load(segment_key);
        }
        analytics.page();
      }
  })();
  /* eslint-enable */
};

const load_madkudujs = function () {
  // only used for testing madkudu.js - gets scrapped from the final code
  /* eslint-disable no-undef */
  if (__MADKUDU_JS__) {
    /* eslint-enable no-undef */

    (function (window, document) {
      const madkudu = window.madkudu || [];
      if (!madkudu.initialize) {
        // prevent from loading twice
        if (madkudu.invoked) {
          if (window.console && console.error) {
            console.error('MadKudu snippet included twice.');
          }
        } else {
          // preload the methods
          madkudu.invoked = !0;
          madkudu.methods = [
            'identify',
            'reset',
            'group',
            'ready',
            'page',
            'track',
            'once',
            'on',
            'smart_form',
          ];
          madkudu.factory = function (t) {
            return function () {
              // eslint-disable-next-line
              const e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              madkudu.push(e);
              return madkudu;
            };
          };
          for (let t = 0; t < madkudu.methods.length; t += 1) {
            const e = madkudu.methods[t];
            madkudu[e] = madkudu.factory(e);
          }
          madkudu.load = function () {
            const src = 'http://localhost:3004/madkudu.js';
            // var src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.madkudu.com/madkudu.js/v1/" + api_key + "/madkudu.min.js";
            // if require is here, load with it
            if (typeof window.define === 'function' && window.define.amd) {
              window.require([src]);
              // otherwise, add the script to the page
            } else {
              const e = document.createElement('script');
              e.type = 'text/javascript';
              e.async = !0;
              e.src = src;
              const n = document.getElementsByTagName('script')[0];
              n.parentNode.insertBefore(e, n);
            }
          };
          madkudu.SNIPPET_VERSION = '0.3.0';
          madkudu.load('985ccd7664cec040468ba02ac8864151');
          madkudu.page();
          // madkudu.form();
        }
      }
      // eslint-disable-next-line
    })(window, document);
  }
};

const load_scripts = function () {
  load_rollbar();

  load_segment();

  load_madkudujs();
};

load_scripts();
