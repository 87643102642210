import Backbone from 'backbone';
import { BackboneViewProps } from '../types';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #mki_chat': 'show_chat',
  },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.render();
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const html = template({ tenant: this.tenant });
    this.$el.html(html);
  },

  show_chat(e: any) {
    e.preventDefault();
    if (window.Intercom) {
      window.Intercom('show');
    }
  },
});
