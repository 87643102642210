import axios from 'axios';
import React from 'react';
import { Form, FormControl, FormGroup, Radio } from 'react-bootstrap';
import Swal from 'sweetalert2';

type FieldsSelectionContainerProps = {
  tenant: number;
  field_pulled: UnknownObject;
  object: string;
  integration: string;
  email: string;
};

type FieldsSelectionContainerState = {
  objects_list: string[];
  field_list: UnknownObject[];
  object: string;
  period: string;
  since: string;
  json: string;
};

export default class FieldSelectionContainer extends React.Component<
  FieldsSelectionContainerProps,
  FieldsSelectionContainerState
> {
  constructor(props: FieldsSelectionContainerProps) {
    super(props);

    const objects_list =
      this.props.integration === 'hubspot'
        ? ['contact', 'deal', 'company']
        : this.props.integration === 'snowflake'
        ? ['contact', 'account', 'event']
        : [
            'Lead',
            'Contact',
            'Account',
            'Opportunity',
            'Task',
            'Campaign',
            'CampaignMember',
            'Custom',
          ];

    this.state = {
      objects_list,
      field_list: [],
      object: props.object,
      period: '1year',
      since: '',
      json: '',
    };
    // eslint-disable-next-line
    this.requestFields(props.object);

    this.generateDemand = this.generateDemand.bind(this);
  }

  generateDemand() {
    return {
      email: this.props.email,
      tenant: this.props.tenant,
      connector: this.props.integration,
      object: this.state.object,
      list: this.state.field_list
        .filter((field) => field.will_be_pulled && !field.previously_pulled)
        .map((field) => field.name),
      period: this.state.period === 'custom' ? this.state.since : null,
    };
  }

  generateRecap() {
    const data = this.generateDemand();
    let list_update = '';
    let counterp = 0;
    // eslint-disable-next-line
    for (const field of this.state.field_list) {
      if (field.will_be_pulled && !field.previously_pulled) {
        list_update += `<div>    - ${field.name}</div>`;
        counterp += 1;
      }
    }
    const html_text = `
    <p>You are about to pull <b>${counterp}</b> new field(s) for this tenant.</p>
    <p>You will receive an email when executed!</p>
    <div>[REQUEST BY]   ${data.email}</div>
    <div>[TENANT]   ${data.tenant}</div>
    <div>[CONNECTOR]   ${data.connector}</div>
    <div>[OBJECT]   ${data.object}</div>
    ${list_update}
    <div>[PERIOD]</div><div> ${data.period ? data.period : '1 year'}</div>`;

    return {
      added_fields: counterp,
      html: html_text,
    };
  }

  displayRecap() {
    const recap = this.generateRecap();

    let alert_type: 'warning' | 'info' = 'warning';
    let alert_title = 'Please verify the fields to pull';
    if (recap.added_fields === 0) {
      alert_type = 'info';
      alert_title = 'No differences with current pull configuration';
      recap.html = '';
    }

    Swal.fire({
      title: alert_title,
      html: recap.html,
      icon: alert_type,
      showCancelButton: true,
      confirmButtonText: 'Pull data',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result: any) => {
      // eslint-disable-next-line
      if (result.value) this.triggerPull();
    });
  }

  async triggerPull() {
    try {
      Swal.fire({
        title: 'Sending request.',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });
      Swal.showLoading();

      const data = this.generateDemand();
      const {
        status,
        data: responseData,
      } = await axios.post(
        `${BONGO_URL}/v1/org/${this.props.tenant}/integrations/${this.props.integration}/pull/trigger`,
        { data }
      );

      if (status < 200 || status > 300) {
        throw new Error(
          `The request failed with code ${status}\n ${responseData}`
        );
      }

      await Swal.fire(
        'Request sent!',
        'The pull will start shortly.<br />You will receive an email when finished',
        'success'
      );
    } catch (err) {
      await Swal.fire(
        'Request failed!',
        `Something went wrong:<br />${err.message}`,
        'error'
      );
    }
  }

  async requestFields(object: string) {
    const res = await axios.get(
      `${BONGO_URL}/v1/org/${this.props.tenant}/integrations/${
        this.props.integration
      }/data/${object.toLowerCase()}/fields`,
      {}
    );
    const list = res.data.fields;

    // eslint-disable-next-line
    for (const item of list) {
      if (this.props.field_pulled[object]) {
        // eslint-disable-next-line
        for (const pulled of this.props.field_pulled[object].attributes) {
          if (item.name.toLowerCase() === pulled.name.toLowerCase())
            // eslint-disable-next-line
            item.will_be_pulled = item.previously_pulled = true;
        }
        if (!item.will_be_pulled)
          // eslint-disable-next-line
          item.will_be_pulled = item.previously_pulled = false;
      }
    }
    this.setState({ field_list: list, object });
  }

  // Action handlers

  handleUpdateIsPulled(id: number) {
    const copy = this.state.field_list.slice();
    copy[id].will_be_pulled = !copy[id].will_be_pulled;
    this.setState({ field_list: copy });
  }

  handleObjectChange() {
    this.setState({ field_list: [] });
    const curr_object = (document.getElementById(
      'object_selector'
    ) as HTMLSelectElement).value;
    const object = curr_object || 'Lead';
    if (object === 'Custom') {
      this.setState({ object: 'Custom' });
    } else {
      // eslint-disable-next-line
      this.requestFields(object);
    }
  }

  handleChangePeriod(period: string) {
    this.setState({
      period,
    });
  }

  handleChangeSince(since: string) {
    this.setState({
      since,
    });
  }

  render() {
    return (
      <div>
        <p>
          In this section, CSEs are able to choose which field they want to pull
          and generate the JSON file
        </p>
        <div>
          <p>Which object?</p>
          <select
            id="object_selector"
            style={{ width: '100%', maxWidth: '110px' }}
            className="form-control"
            value={this.state.object}
            onChange={() => this.handleObjectChange()}
          >
            {this.state.objects_list.map((object) => (
              <option key={object} value={object}>
                {object}
              </option>
            ))}
          </select>
        </div>
        {this.state.field_list.length > 0 ? (
          <div
            className="mt-3 mb-4"
            style={{
              height: '400px',
              overflowY: 'scroll',
              position: 'relative',
              borderRadius: '0.375rem',
              border: '1px solid rgba(0, 0, 0, 0.125)',
            }}
          >
            <table className="table mx-auto" style={{ tableLayout: 'auto' }}>
              <thead>
                <tr className="sticky-top" style={{ backgroundColor: 'white' }}>
                  <th>Pull?</th>
                  <th>Name</th>
                  <th>Label</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {this.state.field_list.map(
                  (
                    { label, type, name, will_be_pulled, previously_pulled },
                    id
                  ) => (
                    <tr key={name}>
                      <td className="td_little">
                        <input
                          type="checkbox"
                          disabled={previously_pulled}
                          defaultChecked={will_be_pulled}
                          onClick={() => this.handleUpdateIsPulled(id)}
                        />
                      </td>
                      <td>{name}</td>
                      <td>{label}</td>
                      <td>{type}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="loader" />
        )}
        <div>
          {this.state.json && (
            <div>
              <textarea
                className="textarea-big"
                readOnly
                value={this.state.json}
              />
            </div>
          )}
        </div>
        <button
          className="btn btn-primary"
          onClick={() =>
            this.setState({ json: JSON.stringify(this.state.field_list) })
          }
        >
          Generate JSON
        </button>
        <h2 className="mt-2">Pull new fields selected automatically</h2>
        <Form inline>
          <FormGroup>
            <Radio
              name="radioGroup"
              inline
              checked={this.state.period === '1year'}
              onChange={() => this.handleChangePeriod('1year')}
            >
              <span style={{ marginLeft: '5px' }}> 1 year of data</span>
            </Radio>
            <Radio
              name="radioGroup"
              inline
              style={{ marginLeft: '15px' }}
              checked={this.state.period === 'custom'}
              onChange={() => this.handleChangePeriod('custom')}
            >
              <span style={{ marginLeft: '5px' }}>since</span>
            </Radio>
            <FormControl
              type="text"
              placeholder="2019-01-01"
              style={{ marginLeft: '5px' }}
              value={this.state.since}
              onChange={(e: any) => this.handleChangeSince(e.target.value)}
            />
          </FormGroup>
        </Form>

        <button
          className="btn btn-primary"
          disabled={
            this.state.period === 'custom' &&
            this.state.since.trim().length === 0
          }
          onClick={() => this.displayRecap()}
        >
          Pull data
        </button>
      </div>
    );
  }
}
