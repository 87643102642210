import moment from 'moment';
import React, { Component } from 'react';

import { Form, FormControl, Label } from 'react-bootstrap';
import { HorizontalBar } from 'react-chartjs-2';
import { ModelTypes } from '../../../types';
import { COLORS } from '../../utils';
import GlobalModelPerformanceCustomerFitGuideComponent from '../guideCards/global_performance/GlobalModelPerformanceCustomerFitGuideComponent';
import GlobalModelPerformanceLeadGradeGuideComponent from '../guideCards/global_performance/GlobalModelPerformanceLeadGradeGuideComponent';
import GlobalModelPerformanceMQAGuideComponent from '../guideCards/global_performance/GlobalModelPerformanceMQAGuideComponent';
import GlobalModelPerformancePQLGuideComponent from '../guideCards/global_performance/GlobalModelPerformancePQLGuideComponent';

type ChartContent = 'recall' | 'leads';

type ChartPeriod =
  | 'this month'
  | 'last month'
  | 'this quarter'
  | 'last quarter';

type GlobalOverviewChartComponentProps = {
  tenant: number;
  leadsAndRecallsForAudienceAndConversion: UnknownObject;
  lastUpdate: string;
  modelType: ModelTypes;
  goTo: (url: string) => void;
};

type GlobalOverviewChartComponentState = {
  content: ChartContent;
  period: ChartPeriod;
};

export default class GlobalOverviewChartComponent extends Component<
  GlobalOverviewChartComponentProps,
  GlobalOverviewChartComponentState
> {
  constructor(props: GlobalOverviewChartComponentProps) {
    super(props);
    this.renderGuide = this.renderGuide.bind(this);
    this.handleChangeContent = this.handleChangeContent.bind(this);
    this.handleChangePeriod = this.handleChangePeriod.bind(this);

    this.state = {
      content: 'recall',
      period: 'this quarter',
    };
  }

  handleChangeContent(content: ChartContent) {
    this.setState({ content });
  }

  handleChangePeriod(period: ChartPeriod) {
    this.setState({ period });
  }

  recallPercentageUpdate(dataToGet: UnknownArray): string {
    const { leadsAndRecallsForAudienceAndConversion } = this.props;

    const { datasets } = leadsAndRecallsForAudienceAndConversion.recall;
    const data = datasets.map(() => 0);
    // eslint-disable-next-line
    for (const val of dataToGet) {
      for (let i = 0; i < datasets.length; i += 1) {
        data[i] +=
          leadsAndRecallsForAudienceAndConversion.recall.datasets[i].data[val];
      }
    }

    const totalRecall = data.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );
    const topElementsSum = data
      .slice(-2)
      .reduce((acc: number, curr: number) => acc + curr, 0);
    let recallPercentage = ((topElementsSum / totalRecall) * 100).toFixed(0);
    // eslint-disable-next-line
    if (isNaN(recallPercentage as any)) {
      recallPercentage = 'N/A';
    }
    return recallPercentage;
  }

  getMonthInQuarter() {
    const month = moment(new Date()).month();
    return month % 3;
  }

  getQuarter() {
    const monthInQuarter = this.getMonthInQuarter();
    switch (monthInQuarter) {
      case 0: // first month of the quarter
        return [5];
      case 1: // second month of the quarter
        return [5, 4];
      case 2: // third month of the quarter
        return [5, 4, 3];
      default:
    }
  }

  getLastQuarter() {
    const monthInQuarter = this.getMonthInQuarter();
    switch (monthInQuarter) {
      case 0: // first month of the quarter
        return [4, 3, 2];
      case 1: // second month of the quarter
        return [3, 2, 1];
      case 2: // third month of the quarter
        return [2, 1, 0];
      default:
    }
  }

  renderGuide() {
    const { tenant, modelType, goTo } = this.props;
    switch (modelType) {
      case 'customer_fit':
        return (
          <GlobalModelPerformanceCustomerFitGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      case 'lead_grade':
        return (
          <GlobalModelPerformanceLeadGradeGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      case 'mqa':
        return (
          <GlobalModelPerformanceMQAGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      case 'pql':
      case 'pql2':
      case 'mql':
        return (
          <GlobalModelPerformancePQLGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      default:
        return <div />;
    }
  }

  renderChart() {
    const { leadsAndRecallsForAudienceAndConversion, modelType } = this.props;
    const title =
      this.state.content === 'leads'
        ? `${modelType === 'mqa' ? 'Accounts' : 'Leads'} distribution`
        : 'Conversions distribution';
    const { content } = this.state;
    const { datasets } = leadsAndRecallsForAudienceAndConversion[content];

    if (!datasets) return null;

    let data_to_get: UnknownArray = [];
    switch (this.state.period) {
      case 'this month':
        data_to_get = [5];
        break;
      case 'last month':
        data_to_get = [4];
        break;
      case 'this quarter':
        data_to_get = this.getQuarter();
        break;
      case 'last quarter':
        data_to_get = this.getLastQuarter();
        break;
      default:
    }

    const aggregatedData = datasets.map(() => 0);
    // eslint-disable-next-line
    for (const val of data_to_get) {
      for (let i = 0; i < datasets.length; i += 1) {
        aggregatedData[i] += datasets[i].data[val];
      }
    }

    const total = aggregatedData.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );
    const currentData = {
      datasets: datasets.map((dataset: UnknownObject, i: number) => {
        return {
          label: dataset.label,
          data: [aggregatedData[i]],
          backgroundColor: COLORS[datasets.length - i - 1],
        };
      }),
    };

    return (
      <HorizontalBar
        data={currentData}
        height={70}
        options={{
          title: { display: true, text: title, fontSize: 16, position: 'top' },
          legend: { position: 'bottom' },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          tooltips: {
            titleFontSize: 0,
            callbacks: {
              label: (item: UnknownObject, data: UnknownObject) =>
                `${data.datasets[item.datasetIndex].label} : ${
                  data.datasets[item.datasetIndex].data
                } leads (${(
                  (data.datasets[item.datasetIndex].data / total) *
                  100
                ).toFixed()} %)`,
            },
          },
          plugins: {
            datalabels: {
              color: 'white',
              formatter(value: number) {
                return `${Math.round((value / total) * 100)}%`;
              },
              display() {
                return false;
              },
            },
          },
        }}
      />
    );
  }

  renderScore() {
    let data_to_get: UnknownArray = [];
    switch (this.state.period) {
      case 'this month':
        data_to_get = [5];
        break;
      case 'last month':
        data_to_get = [4];
        break;
      case 'this quarter':
        data_to_get = this.getQuarter();
        break;
      case 'last quarter':
        data_to_get = this.getLastQuarter();
        break;
      default:
    }
    const score = this.recallPercentageUpdate(data_to_get);

    let textp;
    let colorp;
    if (Number(score) >= 90) {
      textp = 'EXCELLENT';
      colorp = '#398145';
    } else if (Number(score) >= 75) {
      textp = 'GREAT';
      colorp = '#5CAB35';
    } else if (Number(score) >= 50) {
      textp = 'GOOD';
      colorp = '#8CDB65';
    } else if (Number(score) >= 30) {
      textp = 'AVERAGE';
      colorp = '#C2C2C2';
    } else if (Number(score) < 30) {
      textp = 'WARNING';
      colorp = '#DCC72A';
    } else {
      textp = 'No Data Available';
      colorp = '#DCC72A';
    }

    let scorePrint = <div />;
    const scorePrintStyle = {
      fontSize: '90px',
      fontWeight: 'bold',
      marginTop: '-30px',
    };

    if (score === 'N/A') {
      scorePrint = (
        <p style={scorePrintStyle as any}>
          {score}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.madkudu.com/hc/en-us/articles/4407471867405-Recall-and-Precision-how-is-the-performance-of-a-prediction-model-measured-"
            style={{ fontSize: '30px' }}
          >
            ⓘ
          </a>
        </p>
      );
    } else {
      scorePrint = (
        <p style={scorePrintStyle as any}>
          {score}%
          <a
            target={'_blank'}
            rel="noopener noreferrer"
            href="https://support.madkudu.com/hc/en-us/articles/4407471867405-Recall-and-Precision-how-is-the-performance-of-a-prediction-model-measured-"
            style={{ fontSize: '30px' }}
          >
            ⓘ
          </a>
        </p>
      );
    }
    return (
      <div className="col-sm-4 text-center">
        <h5> {this.state.period}</h5>
        {scorePrint}
        <p
          style={{
            color: colorp,
            fontSize: '25px',
            fontWeight: 'bold',
            marginTop: '-30px',
          }}
        >
          {textp}
        </p>
      </div>
    );
  }

  render() {
    const { content, period } = this.state;
    const { modelType } = this.props;

    return (
      <div>
        <div className="row my-3">
          <div className="col-sm-12">{this.renderGuide()}</div>
        </div>
        <div className="row my-3">
          <div className="col-sm-12">
            <div className="card box-shadow-3d">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h4 className="mb-0"> Global Model Performance </h4>
                  </div>
                  <div className="col-6">
                    <Form inline>
                      <Label>Distribution for:</Label>
                      <FormControl
                        className="ml-2 mr-4"
                        componentClass="select"
                        value={content}
                        onChange={(event: any) =>
                          this.handleChangeContent(event.target.value)
                        }
                      >
                        <option value="leads">
                          {modelType === 'mqa' ? 'accounts' : 'leads'}
                        </option>
                        <option value="recall">recall</option>
                      </FormControl>
                      <Label>Selected period:</Label>
                      <FormControl
                        className="ml-2"
                        componentClass="select"
                        value={period}
                        onChange={(event: any) =>
                          this.handleChangePeriod(event.target.value)
                        }
                      >
                        <option value="this month">this month</option>
                        <option value="last month">last month</option>
                        <option value="this quarter">this quarter</option>
                        <option value="last quarter">last quarter</option>
                      </FormControl>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div>
                  <div className="row">
                    <div className="col-sm-8"></div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="row">
                {this.renderScore()}
                <div className="col-sm-8">{this.renderChart()}</div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col-sm-8 font-italic">
                    Data as of {this.props.lastUpdate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
