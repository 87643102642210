import Backbone from 'backbone';

const template = require('./gdpr_request.pug');

export default Backbone.View.extend({
  el: '#main',
  events: {
    'click #summit_GDPR_request': 'reset',
  },

  render() {
    this.$el.html(template());
    $('#success-section').hide();
    return this;
  },

  async reset(e: any) {
    e.preventDefault();

    // ajax call here
    const data = JSON.stringify({
      email_asking_for_a_deletion: $('#email-requester').val(),
      email_to_delete: $('#email-to-delete').val(),
      request: $('#request').val(),
      description: $('#description').val(),
    });

    // send delete request
    // eslint-disable-next-line
    $.post('https://hooks.zapier.com/hooks/catch/1833600/a1j071/', {
      data,
    }).done(function () {
      $('#form-section').hide();
      $('#success-section').show();
    });
  },
});
