import React from 'react';
import Backbone from 'backbone';
import { Button } from 'react-bootstrap';
import AudienceBox from '../audienceBox';
import { redirectToInsightsReport } from '../../utils';
import MappingsMode from '../../enums/MappingsMode';

type OverviewComponentProps = {
  tenant: number;
  isReadOnly: boolean;
  boxData: any[];
  scriptCheckerState: boolean;
};

export default class OverviewComponent extends React.Component<
  OverviewComponentProps,
  {}
> {
  constructor(props: any) {
    super(props);

    this.goBackToMapping = this.goBackToMapping.bind(this);
    this.handleOnClickCreateSimple = this.handleOnClickCreateSimple.bind(this);
    this.handleOnClickCreateCustom = this.handleOnClickCreateCustom.bind(this);
    this.handleOnCheckVolumeClick = this.handleOnCheckVolumeClick.bind(this);
  }

  goBackToMapping() {
    const { tenant } = this.props;

    Backbone.history.navigate(`org/${tenant}/mapping`, true);
  }

  handleOnClickCreateSimple() {
    const { tenant } = this.props;

    Backbone.history.navigate(
      `/org/${tenant}/mapping/audiences/newAudience`,
      true
    );
  }

  handleOnClickCreateCustom() {
    const { tenant } = this.props;

    Backbone.history.navigate(
      `/org/${tenant}/mapping/audiences/newCustomAudience`,
      true
    );
  }

  renderCreateNewButton() {
    const { scriptCheckerState, isReadOnly } = this.props;

    if (!isReadOnly) {
      return (
        <div className="dropdown">
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            disabled={scriptCheckerState}
          >
            Create new audience
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item"
              onClick={this.handleOnClickCreateSimple}
            >
              Create new standard
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleOnClickCreateCustom}
            >
              Create new MadML
            </a>
          </div>
        </div>
      );
    }
    return (
      <div>
        <button
          className="btn btn-primary"
          onClick={this.handleOnClickCreateSimple}
          disabled={scriptCheckerState}
        >
          Create new configuration
        </button>
      </div>
    );
  }

  renderNoAudienceAlert() {
    return (
      <div className="alert alert-warning" role="alert">
        There is no audience configured yet!
      </div>
    );
  }

  handleOnCheckVolumeClick() {
    redirectToInsightsReport(MappingsMode.audience, this.props.tenant);
  }

  render() {
    const { isReadOnly, boxData, tenant, scriptCheckerState } = this.props;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Audience Mapping
            </li>
          </ol>
          {boxData?.length > 0 && (
            <div className="float-right my-2 my-lg-0">
              <Button
                bsStyle="primary"
                className="mr-2"
                onClick={this.handleOnCheckVolumeClick}
              >
                Check volumes
              </Button>
            </div>
          )}
        </nav>
        {scriptCheckerState && (
          <div className="alert alert-warning box-shadow-3d d-flex alert-No-Data">
            <div className="spinner-border float-left mr-2"></div>
            <p>
              Your configuration has been submitted and is currently being
              processed. Please wait for an email to confirm success or failure
              of publish.
            </p>
          </div>
        )}
        <div className="container mt-4 p-3">
          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="card box-shadow-3d mb-4">
                <div className="card-header">
                  <h4 className="mb-0">Audiences</h4>
                </div>
                <div className="card-body">
                  <p>
                    Audiences define cohorts of leads or contacts based on
                    properties from your integrations. Audiences can be used to
                    create models or check the performance of models against
                    different populations. Once created, these audiences are
                    executed daily to include any new record.
                  </p>
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4403660482445-Audience-Mapping "
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-primary mb-4"
                  >
                    <i className="fas fa-book-open"></i> How to create an
                    Audience?
                  </a>
                  {boxData?.length > 0
                    ? boxData.map((boxitem: UnknownObject, index: number) => {
                        return (
                          <AudienceBox
                            tenant={tenant}
                            name={boxitem.name}
                            date={boxitem.date}
                            key={index}
                          />
                        );
                      })
                    : this.renderNoAudienceAlert()}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col text-center">
                  {!isReadOnly && this.renderCreateNewButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
