var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (connector) {
      pug_html = pug_html + "\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav navbar-brand mr-auto mt-2 mt-lg-0\"\u003E\u003Cli class=\"breadcrumb-item\"\u003E\u003Ca class=\"breadcrumb-link text-primary\" id=\"go_to_mapping\"\u003EMapping\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"breadcrumb-item\"\u003E\u003Ca class=\"breadcrumb-link text-primary\" id=\"go_to_event_mapping\"\u003EEvent Mapping\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"breadcrumb-item active\" aria-current=\"page\"\u003E" + (pug.escape(null == (pug_interp = connector) ? "" : pug_interp)) + " mapping\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Ca href=\"https:\u002F\u002Fsupport.madkudu.com\u002Fhc\u002Fen-us\u002Farticles\u002F4415735932557-How-to-create-or-edit-the-Event-mapping-\" target=\"_blank\" rel=\"noopener noreferrer\"\u003E\u003Cbutton class=\"btn btn-primary mr-2\"\u003EDocumentation\u003C\u002Fbutton\u003E\u003C\u002Fa\u003E\u003C\u002Fnav\u003E\u003Cdiv id=\"eventsContainer\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "connector" in locals_for_with ?
        locals_for_with.connector :
        typeof connector !== 'undefined' ? connector : undefined));
    ;;return pug_html;};
module.exports = template;