import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faHeadset } from '@fortawesome/pro-solid-svg-icons/faHeadset';
import { faLifeRing } from '@fortawesome/pro-solid-svg-icons/faLifeRing';
import { faMessageLines } from '@fortawesome/pro-solid-svg-icons/faMessageLines';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  tenant: number;
};
export function Homepage({ tenant }: Props) {
  const show_chat = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  return (
    <div>
      <nav
        aria-label="breadcrumb"
        className="navbar navbar-expand-lg navbar-light bg-light box-shadow-3d"
      >
        <ul className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0">
          <li>Welcome to MadKudu</li>
        </ul>
      </nav>
      <div className="container mt-4 pb-5">
        <div className="card box-shadow-3d border-primary">
          <h4 className="card-header badge-primary">Featured!</h4>
          <div className="row">
            <div className="col-6">
              <div className="card-body p-4">
                <h4 className="mt-2">Sales Copilot</h4>
                <p>
                  Give your sales team the context they need to personalize
                  outreach, and get more meetings booked, directly in
                  Salesforce.
                </p>
                <p>
                  Sales Copilot surfaces relevant insights in your CRM – such as{' '}
                  <b>job openings</b>, <b>tech stack</b>, and{' '}
                  <b>recent activity</b> within your product or on your website.
                </p>
                <div className="text-center">
                  <a
                    href="https://chrome.google.com/webstore/detail/madkudu-sales-intelligenc/jikgjincjapiebekopikcefbkeaimegj"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn btn-teal text-white d-inline-flex align-items-center px-3 py-2 mt-4">
                      Download now
                      <FontAwesomeIcon
                        icon={faExternalLink}
                        size="sm"
                        className="ml-1"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="p-4">
                <a
                  href="https://chrome.google.com/webstore/detail/madkudu-sales-intelligenc/jikgjincjapiebekopikcefbkeaimegj"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/media/onboarding/chrome-ext.png"
                    width="500"
                    height="267"
                    className="mw-100 tw-object-contain"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card box-shadow-3d mt-2">
          <div className="card-header h4">Getting started</div>
          <div className="card-body">
            <div className="card-group">
              <div className="card w-75">
                <h5 className="card-header">Step 1: Connect your Data Stack</h5>
                <div className="card-body">
                  <p className="card-text h-50">
                    Connect your CRM, MAP, and web &amp; product analytics for
                    MadKudu to start analyzing your data.
                  </p>
                  <div className="text-center">
                    <a
                      href={`https://app.madkudu.com/org/${tenant}/integrations`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-primary my-2">
                        Integrations &#8594;
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card w-75">
                <h5 className="card-header">
                  Step 2: Surface Sales Copilot in your CRM
                </h5>
                <div className="card-body">
                  <p className="card-text h-50">
                    Start surfacing to your Sales team the context they need to
                    reach out.
                  </p>
                  <div className="text-center">
                    <a
                      href="https://support.madkudu.com/hc/en-us/articles/15499620008205-MadKudu-Salesforce-Package"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-primary text-white my-2">
                        Install MadKudu package &#8594;
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="card w-75">
                <h5 className="card-header">Step 3: Run playbooks</h5>
                <div className="card-body">
                  <p className="card-text h-50">
                    Focus your marketing and sales efforts with actionable
                    playbooks.
                  </p>
                  <div className="text-center">
                    <a
                      href="https://support.madkudu.com/hc/en-us/articles/17170390250509-Sales-Alerts-"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-primary text-white my-2">
                        Playbooks &#8594;
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="div card box-shadow-3d mt-md-3 d-flex">
              <div className="card-body">
                <img className="mr-2" src="/media/icons/stack.svg" />
                <h5 className="d-inline-block align-middle line-height-2 mb-0">
                  Invite team members
                </h5>
                <a
                  className="float-right"
                  href={`https://app.madkudu.com/org/${tenant}/settings/users`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-primary mt-2">
                    Invite users &#8594;
                  </button>
                </a>
              </div>
            </div>
            <div className="div card box-shadow-3d mt-md-3">
              <div className="card-body">
                <img className="mr-2" src="/media/icons/preparation.svg" />
                <h5 className="d-inline-block align-middle line-height-2 mb-0">
                  Score lists
                </h5>
                <a
                  className="float-right"
                  href={`https://app.madkudu.com/org/${tenant}/integrations/bulk`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-primary mt-2">
                    Bulk Scorer &#8594;
                  </button>
                </a>
              </div>
            </div>
            <div className="div card box-shadow-3d mt-md-3">
              <div className="card-body">
                <img className="mr-2" src="/media/icons/fit.svg" />
                <h5 className="d-inline-block align-middle line-height-2 mb-0">
                  Explain a Lead score
                </h5>
                <a
                  className="float-right"
                  href={`https://app.madkudu.com/org/${tenant}/profiles`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-primary mt-2">
                    Score Lookup &#8594;
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="div card box-shadow-3d mt-md-3">
              <h5 className="card-header">Need help?</h5>
              <div className="card-body">
                <p className="card-text mb-2">
                  We're here to help! Our Support &amp; Enablement team is here
                  for you, please contact us or schedule a call. It's not a
                  Sales call, promise!
                </p>
                <div className="button-group text-center">
                  <div>
                    <a
                      href="https://support.madkudu.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-primary mt-2 w-50">
                        <FontAwesomeIcon
                          icon={faLifeRing}
                          size="lg"
                          className="mr-1"
                        />{' '}
                        Help Center
                      </button>
                    </a>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary mt-2 w-50"
                      onClick={() => show_chat()}
                    >
                      <FontAwesomeIcon
                        icon={faMessageLines}
                        size="lg"
                        className="mr-1"
                      />{' '}
                      Chat with us
                    </button>
                  </div>
                  <div>
                    <a
                      href="https://support.madkudu.com/hc/en-us/requests/new"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-primary mt-2 w-50">
                        <FontAwesomeIcon
                          icon={faHeadset}
                          size="lg"
                          className="mr-1"
                        />{' '}
                        Schedule a call
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
