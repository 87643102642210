import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';

type OverviewComponentProps = {
  loading: boolean;
  isConnected: boolean;
  handleTabClick: Function;
};

export default function OverviewComponent(props: OverviewComponentProps) {
  const { loading, isConnected, handleTabClick } = props;
  return (
    <div className="d-flex">
      <div className="row w-75 mr-4">
        <div className="col">
          <div className="row justify-space-between">
            <div className="col-8">
              <h5>Overview</h5>
              <div>
                Clearbit provides more than a 100 demographic, firmographic and
                technographic data points on people and companies. MadKudu
                partners with Clearbit.
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h5>Best Used For</h5>
            <div>
              <p>
                &bull; <b>Clearbit Enrichment</b>: score and route the best
                leads straight to Sales
              </p>
              <p>
                &bull; <b>Clearbit Reveal</b>: deanonymize your website traffic
                and improve your MadKudu and identify qualified accounts showing
                intent.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>How to integrate Clearbit to MadKudu</h5>
            <div>
              <p>
                &bull; <b>Clearbit Enrichment</b>: MadKudu is a native Clearbit
                integration.&nbsp;
                <a href="#" onClick={() => handleTabClick('configuration')}>
                  Go to configuration
                </a>{' '}
                to connect your Clearbit account.
              </p>
              <p>
                &bull; <b>Clearbit Reveal</b>: To use Clearbit Reveal in
                MadKudu, please use the Clearbit Reveal &lt;&gt; Segment
                integration and connect Segment to MadKudu.{' '}
                <a
                  href="https://support.madkudu.com/hc/en-us/articles/17528123078797-Clearbit-Reveal-How-to-send-de-anonymized-web-traffic-to-MadKudu-via-Segment"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more...
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-25">
        <p>
          <b>Status</b>
          <span className="d-block pt-1">
            {loading ? (
              <span className="text-secondary">loading...</span>
            ) : (
              <span
                className="cursor-pointer d-block"
                onClick={() => handleTabClick('configuration')}
              >
                {isConnected ? (
                  <b className="tw-text-green-900 d-block p-2 rounded tw-bg-[#c5f3da]">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Connected
                  </b>
                ) : (
                  <b className="tw-text-gray-500 d-block p-2 rounded tw-bg-gray-100">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Not Connected
                  </b>
                )}
              </span>
            )}
          </span>
        </p>
        <p className="mt-4">
          <b>Links</b>
        </p>
        <p>
          <a
            href="https://clearbit.com/platform/enrichment"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clearbit Enrichment
          </a>
        </p>
        <p>
          <a
            href="https://clearbit.com/platform/reveal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clearbit Reveal
          </a>
        </p>
        <p>
          <a
            href="https://support.madkudu.com/hc/en-us/articles/17528123078797-Clearbit-Reveal-How-to-send-de-anonymized-web-traffic-to-MadKudu-via-Segment"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to integrate Clearbit Reveal to MadKudu
          </a>
        </p>
      </div>
    </div>
  );
}
